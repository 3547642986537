import React, {useState, useEffect} from "react";
import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCamera, faAngleRight, faExclamationCircle, faDownload, faFilePdf, faChevronRight, faCheck, faClock, faCopy, faX } from "@fortawesome/free-solid-svg-icons";
import './styles.css';
import {  getStoredJson, randomNumericString, saveJsonData, sleep, useInitialCheck, useInterval } from "../../../utils";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { useNavigate } from 'react-router-dom';
import CustomDonutChart from "../../../components/Graphs/CustomDonutChart";
import { getDataBaseCondition, getDatabaseRows, getImagePaths, postApiData, url } from "../../../utils/database";
import lang from "./lang";
import CustomModal from "../../../components/Modal";
import CustomTextField from "../../../components/CustomTextField";
import AsyncButton from "../../../components/AsyncButton";
import validator from "validator";


function renderCameraIcon(width: string, height: string, img: any = ''){
    if(!img || img == '')
        return (
            <div className='cameraContainer' style={{width: width, height: height}}>
                <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
            </div>
        )
    
    return (<img className='cameraContainer' style={{width: width, height: height}} src={img} alt=""/>)
}

export function RegionPage(){
    const [selectedOption, setSelectedOption] = useState<string>('general')
    const [searchItems, setSearchItems] = useState<any>([])
    const [allInspections, setAllInspections] = useState<any>([null])
    const [allData, setAllData] = useState<any>([null])
    const [target, setTarget] = useState<string>('company')
    const [form, setForm] = useState<any>([])
    const [inspectionsData, setInspectionsData] = useState<any>([])
    const [percentage, setPercentage] = useState<any>([])
    const [noInspection, setNoInspection] = useState<any>([])
    const [documents, setDocuments] = useState<any>([])
    const [images, setImages] = useState<any>([])
    const [email, setEmail] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
	const [formLink, setFormLink] = useState<string>('')
    const [copy, setCopy] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)
	const [emailWarning, setEmailWarning] = useState(false)

    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()
    useInitialCheck(importAtivos)
    useInitialCheck(importImages, [searchItems])
    useInitialCheck(importDocuments, [searchItems])
    
    useEffect(() => {
        importInspections()
    }, [searchItems])

    async function importImages() {
        const stored = getStoredJson('searchRegion')
        
        if(!stored)
            return

        const images = await getImagePaths(stored.img_path)

        if(!images || images.length == 0)
            return

        setImages(images)
    }

    function importInspections(){
        const item = searchItems
        
        let insp;
        let inspectedEquip;
        let equips;
        let notInspected;
        let suport: any;
        let equipPercentage;
        let sup2 = true;

        if(target == 'company'){
            suport = item.map((it: any) => it.plant_id)
            insp = allInspections.filter((inspec: any) => suport.includes(inspec?.plant))
            inspectedEquip = Array.from(new Set(insp.filter((item: any) => item?.approved == 1).map((item: any) => item.equipment))).length;
            equips = allData.filter((equip: any) => suport.includes(equip?.plant)).length
         
            if(inspectedEquip == 0)
                sup2 = false
        } 
        else if(target == 'plant'){
            suport = item.map((it: any) => it.process_id)
            insp = allInspections.filter((inspec: any) => suport.includes(inspec?.process))
            inspectedEquip = Array.from(new Set(insp.filter((item: any) => item?.approved == 1).map((item: any) => item.equipment))).length;
            equips = allData.filter((equip: any) => suport.includes(equip?.process)).length
            if(inspectedEquip == 0)
                sup2 = false
        } 
        else if(target == 'process'){
            suport = item.map((it: any) => it.equipment_id)
            insp = allInspections.filter((inspec: any) => suport.includes(inspec?.equipment))
            inspectedEquip = Array.from(new Set(insp.filter((item: any) => item?.approved == 1).map((item: any) => item.equipment))).length;
            equips = allData.filter((equip: any) => suport.includes(equip?.equipment_id)).length
            if(inspectedEquip == 0)
                sup2 = false
        }

        if(inspectedEquip){
            notInspected = equips - inspectedEquip;
            equipPercentage = (inspectedEquip / (inspectedEquip + notInspected) * 100).toFixed(1);        
        }
        if (sup2 == true){
            setPercentage(equipPercentage)
            setNoInspection(false)
            setInspectionsData([
                { name: 'not pending', value: inspectedEquip, color: '#F36934' }, 
                { name: 'pending', value: notInspected, color: '#FFC1A9' }, 
                { name: 'due', value: 0, color: 'red' }, 
            ])
        }
        else {
            setPercentage(0)
            setNoInspection(true)
            setInspectionsData([
                { name: 'not pending', value: 100, color: 'grey' }, 
                { name: 'pending', value: 0, color: '#FFC1A9' }, 
                { name: 'due', value: 0, color: 'red' }, 
            ])
        }
        
    }

    async function importAtivos(){
        const search = getStoredJson('searchRegion')
        const inspections = await getDatabaseRows('inspections')

        let data = await getDatabaseRows('equipments')
        let response: any
        let target:   any
        let impForm = [];

        if(search.cnpj){
            response = await getDataBaseCondition('plants', 'company', search.cnpj)
            target = 'company'
        } 
        else if(search.plant_id){
            response = await getDataBaseCondition('processes', 'plant', search.plant_id)
            target = 'plant'
            impForm = await getDataBaseCondition('forms', 'asset_id', search.plant_id)
        } 
        else if(search.process_id){
            response = await getDataBaseCondition('equipments', 'process', search.process_id)
            target = 'process'
            impForm = await getDataBaseCondition('forms', 'asset_id', search.process_id)
        } 
        else if(search.equipment_id){
            response = await getDataBaseCondition('equipments', 'name', search.equipment_id)
            target = 'equipment'
            impForm = await getDataBaseCondition('forms', 'asset_id', search.equipment_id)
        }
        
        setTarget(target)
        setForm(impForm.length > 0 ? impForm[0] : {})

        for(let x=0; x<response.length; x++){
            const newData = response[x]
            //alert(JSON.stringify(newData))
            const images  = await getImagePaths(newData.img_path)
            response[x].img = images.length > 0 ? images[0] : ''
            
            if(target == 'process'){
                const items = await getDataBaseCondition('inspections', 'equipment', newData.equipment_id)
                
                if(items.length > 0)
                    response[x].last_inspection = items[items.length - 1].deadline
            }
        }

        setAllData(data)
        setAllInspections(inspections)
        setSearchItems(response)
    }

    async function importDocuments(){
        const stored = getStoredJson('searchRegion')
        const files = await getImagePaths(stored.doc_path)
        setDocuments(files)
    }

    function handleEquipmentSelected(item: any){
        saveJsonData('equipmentInfo', item)
        return navigate('/Document')
    }

    function renderEquipmentOptions(){
        return searchItems.map((item: any) => (
            <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => handleClickActive(item)}>
                <div style={{display: 'flex', justifyContent: 'space-between', height: '20vh'}}>
                    <div style={{display: 'flex'}}>
                        {renderCameraIcon('15vw', '15vh', item.img)}
                        <div style={{padding: '.8rem'}}/>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <div style={{padding: '.4rem'}}/>
                            <p className="individualText">{item.name}</p>
                            <p className="individualText">{lang.lastInspectionIn[currLang]}: {item.last_inspection ? item.last_inspection : 'None'}</p>

                            
                            <div style={{padding: '.4rem'}}/>
                        </div>
                    </div>

                    <FontAwesomeIcon icon={faAngleRight}
                                     style={{padding: '1rem', fontSize: '2rem'}}
                                     className="iconLeft"
                                     onClick={() => handleEquipmentSelected(item)}
                                     />
                </div>

                <div className="line" style={{opacity: .8}}/>
            </div>
            )
        )
    }

    function renderProgressBar(item: any){
        let inspections;
        let missingInspections;
        let phPercentage = 1;

        if(target == 'company'){
            inspections = allInspections.filter((insp: any) => insp.plant == item.plant_id);
            missingInspections = inspections.filter((insp: any) => insp.approved == 1)
        } 
        else if(target == 'plant'){
            inspections = allInspections.filter((insp: any) => insp.process == item.process_id);
            missingInspections = inspections.filter((insp: any) => insp.approved == 1)
        } 
        
        let percentage = missingInspections.length/inspections.length*100

        return (
            <div>
                <div style={{display: 'flex', width: '100%'}}>
                    <div 
                        className='barContainerFilled'
                        style={{
                            width: percentage ? (String(percentage) + '%') : (String(phPercentage) + '%'),
                            borderTopRightRadius: (percentage == 100) ? '1rem' : '0',
                            borderBottomRightRadius: (percentage == 100) ? '1rem' : '0',
                        }}
                    />
                    
                    <div className='barContainerEmpty'  style={percentage ? {width: String(100-percentage) + '%'} : {width: String(100-phPercentage) + '%'} }/>
                </div>
                
                <p 
                    style={{display: 'flex', color: '#F36934', justifyContent: 'flex-end'}}
                >
                    {missingInspections.length} {lang.of[currLang]} {inspections.length} {lang.inspected[currLang].toLowerCase()}
                </p>
            </div>
        )
    }

    function handleArrowClick(name: any){
        return navigate('/Region')
    }

    function handleClickActive(item: any){
        saveJsonData('searchRegion', item)
        importAtivos()
    }

    function EquipmentSelection ({item}: any){
        return(
            <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => handleClickActive(item)}>
                <div style={{display: 'flex', justifyContent: 'space-between', height: '20vh'}}>
                    <div style={{display: 'flex', width: '90%'}}>
                        {renderCameraIcon('15vw', '85%', item.img)}
                        <div style={{padding: '.8rem'}}/>
                    
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '65%'}}>
                            <div/>
                            
                            <div>
                                <p className="individualText">{item.name}</p>
                                <div style={{padding: '.8rem'}}/>
                                {renderProgressBar(item)}
                            </div>

                            <div/>
                            <div/>
                        </div>
                    </div>

                    <FontAwesomeIcon icon={faAngleRight}
                                     style={{padding: '1rem', fontSize: '2rem'}}
                                     className="iconLeft"
                                     onClick={()=> handleArrowClick(item.name)}
                                     />
                </div>
                
                <div className="line" style={{opacity: .8}}/>
            </div>
        )
    }

    function renderAssetsOptions(){
        if(getStoredJson('searchRegion').process_id)
            return renderEquipmentOptions()

        if(getStoredJson('searchRegion').equipment_id)
            return renderIndividualEquipments()
        
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {searchItems.map((item: any) => <EquipmentSelection item={item}/>)}
            </div>
        );
    }

    function createLabelOption(text: string, color: string, inspection: any){
        return (
            <div style={{display: 'flex'}}>
                {inspection == false ? (
                    <>
                        <div className="coloredBox" style={{backgroundColor: color, borderRadius: '1rem'}}/>
                    </>
                    ) : (
                        <FontAwesomeIcon icon={faExclamationCircle} className="coloredIcon" style={{color: color, borderRadius: '1rem'}}/>
                    )
                }
                <div style={{padding: '.3rem'}}/>
                <p className="labelText" style={{color: "white"}}>{text}</p>
            </div>
        )
    }

    function isModalFieldsEmpty() {
		if (!email)
			return true

		for (let value of email) {
			if (!value || value.trim().length === 0) 
				return true;
		}
	
		return false;
	}

    function handleEmailChange(newEmail: any) {
		setEmail(newEmail);  // Atualiza o estado do email com o novo valor
		setEmailWarning(false);  // Remove o aviso
	};

    function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

    function handleFormLink() {
        let formLink

        if (target == 'plant')
            formLink = url.replace('api/?', '') + 'Forms?plant-' + form.asset_id
        else if (target == 'process')
            formLink = url.replace('api/?', '') + 'Forms?process-' + form.asset_id

		setFormLink(String(formLink))
        return
	}

    async function handleSendMail(){
		if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		
		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		setEmailModalOpen(false)

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}

    function handleEmailModal() {
        handleFormLink()
        let emailMsg = lang.emailMsg[currLang]
		setEmailMessage(emailMsg)
        setEmailModalOpen(!emailModalOpen)
    }

    function handleNextPage(item: any) {
		saveJsonData('formVisualized', item)
		return navigate('/FormView')
	}

    function renderGeneralOptions(){
        let disableButton = false
        let search = getStoredJson('searchRegion')

        if(search)
            search = [search]
        else
            search = []

        return search.map((item: any) => (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <p className="generalOptionsTitle">{lang.inspStatus[currLang]}</p>
                
                <div style={{display: 'flex'}}>
                    <div style={{width: '15vw', height: '20vh', alignSelf: 'center'}}>
                        <CustomDonutChart data={inspectionsData} percentage={percentage}/>
                    </div>
                    {noInspection === false ? (
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            {createLabelOption(lang.inspected[currLang], '#F36934', noInspection)}
                            {createLabelOption(lang.notInspected[currLang], '#FFC1A9', noInspection)}
                        </div>
                    ) : (
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            {createLabelOption(lang.noEquipment[currLang], '#F36934', noInspection)}
                        </div>
                    )}
                    
                </div>

                <div style={{padding: '.7rem'}}/>
                <div className="line" style={{opacity: .8}}/>
                <div style={{padding: '.7rem'}}/>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="item_Home">
					<span className="generalOptionsTitle">{lang.readForm[currLang]}</span>
					<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
						<FontAwesomeIcon 
							icon={form ? form?.form_data ? faCheck : faClock : faX} 
							className="iconinline1_Home" 
							title={form ? form?.form_data ? 'OK' : "Preenchimento Pendente" : 'Sem formulário'} 
						/>

						<button 
							className="arrow_Home" 
                            style={form ? undefined : {cursor: 'not-allowed'}}
							onClick={() => form ? form?.form_data ? handleNextPage(form) : handleEmailModal() : disableButton = true}
                            disabled = {disableButton}
						>
							<FontAwesomeIcon icon={faChevronRight} className="iconarrow_Home" />
						</button>
					</div>
				</div>

				<div style={{padding: '.7rem'}}/>
                <div className="line" style={{opacity: .8}}/>
			</div>

            </div>
        ))
    }

    function renderIndividualEquipments(){
        return searchItems.map((item: any) => (
            <div className="individualItem">
                
            </div>
        ))
    }

    function renderLine(){
        return (
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                <div style={{display: 'flex', width: '100%', height: '2px', backgroundColor: 'white'}}/>
            </div>
        )
    }


    function renderDoc(doc: string){
        const url = new URL(doc);
        const pathname = url.pathname;
        const fileName = pathname.split('/').pop();

        const downloadFile = (url: string) => {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName || 'download';
            
            // Simula um clique no elemento <a> para iniciar o download
            document.body.appendChild(anchor); // Adiciona o elemento ao corpo do documento
            anchor.click(); // Simula o clique
            
            // Remove o elemento <a> do documento após o clique
            document.body.removeChild(anchor);
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div className='inspectionOption'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faFilePdf} style={{color: 'white', fontSize: '1.2rem'}}/>
                        <div style={{padding: '.5rem'}}/>
                        <p style={{color: 'white'}}>{fileName}</p>
                    </div>
                    
                    <FontAwesomeIcon onClick={() => downloadFile(doc)} icon={faDownload} className='documentLogoButton'/>
                </div>

                <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: '100%', height: '2px', backgroundColor: 'white'}}/>
                </div>
            </div>
        )
    }

    function renderDocsOptions(){
        return (
            <div className='visualInspectionContainer'>
                {documents.map((item: string) => renderDoc(item))}
            </div>
        )
    }

    return (
        <div className='Region'>
            <CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
                    <div className='textsModalContainer'>
                        <h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
                    </div>
                    <div className='textsModalContainer'>
                        <p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
                    </div>
                    <div className='modalInputText'>
                        <CustomTextField
                            placeholder={'Email'}
                            value={email}
                            setValue={handleEmailChange}
                        />
                    </div>
                    {emailWarning === true && <p style={{ color: 'red'}}>Email inválido!</p>}
                    
                    <div className='modalInputText'>	
                        <CustomTextField
                            placeholder={'Mensagem'}
                            value={emailMessage}
                            setValue={setEmailMessage}
                        />
                    </div>
                    <div className='modalInputText'>
                        <CustomTextField
                            placeholder={'Link'}
                            value={formLink}
                            disabled={true}
                        />
                        <FontAwesomeIcon 
                            className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
                            icon={copy ? faCheck : faCopy} 
                            title="Copiar para área de transferência" 
                            onClick={() => copy ? undefined : handleCopy()} 
                        />
                    </div>
                    
                    <div className='modalButtonWrapper'>
                        <AsyncButton
                            active={loading || isModalFieldsEmpty()}
                            text='Enviar'
                            className='PageModalButtonContainer'
                            onClick={handleSendMail}
                            loading={loading}
                        />

                        <AsyncButton
                            active={loading}
                            text='Fechar'
                            className='PageModalButtonContainer'
                            onClick={handleEmailModal}
                            loading={loading}
                        />
                    </div>
                </CustomModal>

            <FlagSelector/>
            <Sidebar activePage='Region'/>

            <div className="mainRegionContainer">

                <div style={{padding: '3.5vh'}}/>

                <div className='grayContainer'>
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={() => navigate('/List')}>
                        <FontAwesomeIcon icon={faAngleLeft} className="iconLeft"/>
                        <div style={{padding: '1%'}}/>
                        <p className="titleText">{getStoredJson('searchRegion').name}</p>
                    </div>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between', height: '40%'}}>
                        <div>
                            {renderCameraIcon('22vw', '100%', images.length > 0 ? images[0] : '')}
                        </div>

                        <div style={{padding: '.8rem'}}/>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            {renderCameraIcon('15vw', '45%', images.length > 1 ? images[1] : '')}
                     
                            {renderCameraIcon('15vw', '45%', images.length > 2 ? images[2] : '')}
                        </div>
                        
                        <div style={{padding: '.8rem'}}/>

                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                            {renderCameraIcon('15vw', '45%', images.length > 3 ? images[3] : '')}
        
                            {renderCameraIcon('15vw', '45%', images.length > 4 ? images[4] : '')}
                        </div>
                    </div>
                    
                    <div style={{padding: '.8rem'}}/>

                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-evenly'}}>
                        <p className="optionText" 
                           style={{color: (selectedOption == 'general') ?  '#F36934' : 'white'}} 
                           onClick={() => setSelectedOption('general')}
                        >
                           {lang.general[currLang]}        
                        </p>

                        <p className="optionText" 
                           style={{color: (selectedOption == 'documents') ?  '#F36934' : 'white'}} 
                           onClick={() => setSelectedOption('documents')}
                        >
                           {lang.documents[currLang]}        
                        </p>

                        <p className="optionText"
                           style={{color: (selectedOption == 'assets') ? '#F36934' : 'white'}} 
                           onClick={() => setSelectedOption('assets')}
                        >
                           {lang.assets[currLang]}
                        </p>
                    </div>

                    <div style={{padding: '.2rem'}}/>
                    
                    {renderLine()}
                    
                    <div style={{padding: '.4rem'}}/>

                    <div className="scrollView">
                        {
                            (selectedOption == 'general')   ? renderGeneralOptions() :
                            (selectedOption == 'documents') ? renderDocsOptions()    : renderAssetsOptions()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

