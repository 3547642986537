import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlagSelector from '../../../components/FlagSelector'
import Sidebar from '../../../components/Sidebar'
import './styles.css'
import { faAngleLeft, faAngleRight, faCamera, faCheck, faChevronRight, faClock, faCopy, faDownload, faFilePdf, faWarning, faX } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { getStoredJson, randomNumericString, saveJsonData, sleep, useInitialCheck } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { getDataBaseCondition, getDatabaseRows, getImagePaths, postApiData, url } from '../../../utils/database'
import { formatTimestamp, getDeadline } from '../../../components/DatePicker'
import AsyncButton from '../../../components/AsyncButton'
import CustomModal from '../../../components/Modal'
import CustomTextField from '../../../components/CustomTextField'
import validator from 'validator'
import { useGlobalContext } from '../../../contexts/GlobalContext'
import lang from "./lang";
import ScrollView from '../../../components/ScrollView'


function renderCameraIcon(width: string, height: string, img: any = ''){
    if(!img || img == '')
        return (
            <div className='cameraContainer' style={{width: width, height: height}}>
                <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
            </div>
        )
    
    return (<img className='cameraContainer' style={{width: width, height: height}} src={img} alt=""/>)
}


export default function DocumentPage(){
    const [equipmentData, setEquipmentData]   = useState<any>({img:[], inspections: [], docs: []})
    const [selectedOption, setSelectedOption] = useState('general')
    const [form, setForm] = useState<any>([])
    const [email, setEmail] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
	const [formLink, setFormLink] = useState<string>('')
    const [copy, setCopy] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)
	const [emailWarning, setEmailWarning] = useState(false)
    const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})

    const { globalOptions, setGlobalOptions } = useGlobalContext()
    const currLang = globalOptions.language

    useInitialCheck(importEquipmentData)
    const navigate = useNavigate()

    async function importEquipmentData(){
        let stored: any = getStoredJson('equipmentInfo')
        let impForm
        if(!stored)
            return navigate('/Home')
        
        stored.img = await getImagePaths(stored.img_path)

        stored.company = await getDataBaseCondition('companies', 'cnpj', stored.company)
        stored.company = stored.company.length > 0 ? stored.company[0].name : 'N/A'

        stored.plant = await getDataBaseCondition('plants', 'plant_id', stored.plant)
        stored.plant = stored.plant.length > 0 ? stored.plant[0].name : 'N/A'
        
        stored.process = await getDataBaseCondition('processes', 'process_id', stored.process)
        stored.process = stored.process.length > 0 ? stored.process[0].name : 'N/A'
        
        stored.inspections = await getDataBaseCondition('inspections', 'equipment', stored.equipment_id)
        stored.docs = await getImagePaths(stored.doc_path)

        impForm = await getDataBaseCondition('forms', 'asset_id', stored.equipment_id)

        setForm(impForm[0])
        setEquipmentData(stored)
    }

    function createTopBar(){
        return (
            <div className="ExploreRow" style={{justifyContent: 'space-evenly'}}>
                <p 
                    className="optionTextNew" 
                    onClick={() => setSelectedOption('general')}
                    style={{color: selectedOption == 'general' ? '#f36934' : 'white'}}
                >
                    Geral
                </p>

                <p 
                    className="optionTextNew" 
                    onClick={() => setSelectedOption('inspections')}
                    style={{color: selectedOption == 'inspections' ? '#f36934' : 'white'}}
                >
                    Inspeções
                </p>

                <p 
                    className="optionTextNew" 
                    onClick={() => setSelectedOption('technic_data')}
                    style={{color: selectedOption == 'technic_data' ? '#f36934' : 'white'}}
                >
                    Dados Técnicos
                </p>

                <p 
                    className="optionTextNew" 
                    onClick={() => setSelectedOption('docs')}
                    style={{color: selectedOption == 'docs' ? '#f36934' : 'white'}}
                >
                    Documentos
                </p>
            </div>
        )
    }

    function renderGeneralOption(key: string, value: string|number='', icon: any=null){
        let disabledButton = false
        return (
            <div className='inspectionOption'>
                <p style={{color: 'white'}}>{key}</p>
                {icon ? (<FontAwesomeIcon icon={icon} style={value == 'form' ?  undefined : {color: 'white', fontSize: '1.2rem'}} className={value == 'form' ? 'iconinline1_Home' : ''}/>) : (<p style={{color: 'white'}}>{value}</p>)}
                {value == 'form' ? (
                <button 
                    className="arrow_Home" 
                    style={form ? {marginLeft: '-85%'} : {cursor: 'not-allowed', marginLeft: '-85%'}}
                    onClick={() => form ? form?.form_data ? handleFormPage(form) : handleEmailModal() : disabledButton = true}
                    disabled={disabledButton}
                >
                    <FontAwesomeIcon icon={faChevronRight} className="iconarrow_Home"/>
                </button>
                ) : null}
            </div>
        )
    }

    function handleFormPage(item: any) {
        saveJsonData('searchRegion', equipmentData)
		saveJsonData('formVisualized', item)
		return navigate('/FormView')
	}

    function isModalFieldsEmpty() {
		if (!email)
			return true

		for (let value of email) {
			if (!value || value.trim().length === 0) 
				return true;
		}
	
		return false;
	}

    function renderGeneralOptions(){
        let last_inspection = 'N/A'
        let visual_inspection = 'N/A'
        let width_loss = 'N/A'

        if(equipmentData.inspections.length > 0)
            last_inspection = equipmentData.inspections[equipmentData.inspections.length-1].deadline

        return (
            <div className='visualInspectionContainer'>
                {renderGeneralOption('Planta', equipmentData.plant)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Processo', equipmentData.process)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Última Inspeção', last_inspection)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Perda de Espessura', '', faCheck)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Inspeção Visual', '', faWarning)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Vida Útil Restante', '38')}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Formulario', 'form', form ? form?.form_data ? faCheck : faClock : faX)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                
            </div>
        )
    }

    function renderInspection(item: any){
        return (
            <div className='IndividualInspectionContainer'>
                <div style={{display: 'flex', width: '40%', color: 'white'}}>
                    {item.title}
                </div>

                <div style={{display: 'flex', width: '20%', color: 'white', justifyContent: "center"}}>
                    {String(formatTimestamp(item.deadline))}
                </div>

                <div style={{display: 'flex', width: '20%', color: 'white', justifyContent: 'flex-end'}}>
                    <FontAwesomeIcon icon={faAngleRight} color='white' fontSize='1.2rem' style={{cursor: 'pointer'}}/>
                </div>
            </div>
        )
    }

    function handleNextPage(item: any){
        saveJsonData('pendingInspection', item)
		return navigate('/Explore')
    }
    
    function renderinspectionsOptions(){
        return equipmentData.inspections.map((item: any) => (
            <div className='measurementContainer' onClick={() => handleNextPage(item)}>
                {renderInspection(item)}    
            
                <div className="line"/>
            </div>
        ))
    }

    function renderThecnicOptions(){
        return (
            <div className='visualInspectionContainer'>
                {renderGeneralOption('Isolamento Térmico', 'Ativo EFO')}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Probabilidade de Ocorrência de risco', 'PTG')}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Risco de Consequência', '27-04-2023')}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('EFC', '', faCheck)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Quantidade de geratrizes de limite', '', faWarning)}
                <div className="line" style={{paddingTop: '.1%'}}/>

                {renderGeneralOption('Quantidade de Geratrizes Envolvidas', '38')}
                <div className="line" style={{paddingTop: '.1%'}}/>
            </div>
        )
    }

    function renderDoc(doc: string){
        const url = new URL(doc);
        const pathname = url.pathname;
        const fileName = pathname.split('/').pop();

        const downloadFile = (url: string) => {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName || 'download';
            
            // Simula um clique no elemento <a> para iniciar o download
            document.body.appendChild(anchor); // Adiciona o elemento ao corpo do documento
            anchor.click(); // Simula o clique
            
            // Remove o elemento <a> do documento após o clique
            document.body.removeChild(anchor);
        }

        return (
            <div className='inspectionOption'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faFilePdf} style={{color: 'white', fontSize: '1.2rem'}}/>
                    <div style={{padding: '.5rem'}}/>
                    <p style={{color: 'white'}}>{fileName}</p>
                </div>

                <FontAwesomeIcon onClick={() => downloadFile(doc)} icon={faDownload} className='documentLogoButton'/>
            </div>
        )
    }

    function renderDocsOptions(){
        return (
            <div className='visualInspectionContainer'>
                {equipmentData.docs.map((item: string) => renderDoc(item))}
            </div>
        )
    }

    function handleFormLink() {
        let formLink

        formLink = url.replace('api/?', '') + 'Forms?equipment-' + form.asset_id
		setFormLink(String(formLink))
        return
	}

    function handleEmailModal() {
        handleFormLink()
        let emailMsg = 'teste email'
		setEmailMessage(emailMsg)
        setEmailModalOpen(!emailModalOpen)
    }

    function handleEmailChange(newEmail: any) {
		setEmail(newEmail);  // Atualiza o estado do email com o novo valor
		setEmailWarning(false);  // Remove o aviso
	};

    function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

    async function handleSendMail(){
		if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		setEmailModalOpen(false)

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}

    function DocumentContainer(){
        return (
          <div className='DocumentContainer'>
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                <FontAwesomeIcon icon={faAngleLeft} style={{color: 'white', fontSize: '1.5rem', cursor: 'pointer'}} onClick={() => navigate(-1)}/>
                <div style={{padding: '15px'}}/>
                <p style={{color: 'white', fontSize: '1.5rem'}}>{equipmentData.name}</p>
            </div>

            <div style={{display: 'flex', width: '100%', height: '35%', alignItems: 'center', justifyContent: 'space-between'}}>
                {renderCameraIcon('30%', '100%', equipmentData.img.length > 0 ? equipmentData.img[0] : '')}

                <div style={{display: 'flex', flexDirection: 'column', width: '30%', height: '100%', justifyContent: 'space-between'}}>
                    {renderCameraIcon('100%', '48%', equipmentData.img.length > 1 ? equipmentData.img[1] : '')}
                    {renderCameraIcon('100%', '48%', equipmentData.img.length > 2 ? equipmentData.img[2] : '')}
                </div>

                <div style={{display: 'flex', flexDirection: 'column', width: '30%', height: '100%', justifyContent: 'space-between'}}>
                    {renderCameraIcon('100%', '48%', equipmentData.img.length > 3 ? equipmentData.img[3] : '')}
                    {renderCameraIcon('100%', '48%', equipmentData.img.length > 4 ? equipmentData.img[4] : '')}
                </div>
            </div>
            
            <div style={{padding: '.5rem'}}/>
            {createTopBar()}
            <div className="line"/>

            <ScrollView style={{display: 'flex', width: '100%', flexDirection: 'column', height: '35vh'}}>
                {
                    (selectedOption == 'general')      ? renderGeneralOptions()      : 
                    (selectedOption == 'inspections')  ? renderinspectionsOptions() : 
                    (selectedOption == 'technic_data') ? renderThecnicOptions()      : 
                    (selectedOption == 'docs')         ? renderDocsOptions()         : <div/>
                }
            </ScrollView>
          </div>
        )
    }

    return (
        <div className="MainContainer">
            <CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
                    <div className='textsModalContainer'>
                        <h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
                    </div>
                    <div className='textsModalContainer'>
                        <p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
                    </div>
                    <div className='modalInputText'>
                        <CustomTextField
                            placeholder={'Email'}
                            value={email}
                            setValue={handleEmailChange}
                        />
                    </div>
                    {emailWarning === true && <p style={{ color: 'red'}}>Email inválido!</p>}
                    
                    <div className='modalInputText'>	
                        <CustomTextField
                            placeholder={'Mensagem'}
                            value={emailMessage}
                            setValue={setEmailMessage}
                        />
                    </div>
                    <div className='modalInputText'>
                        <CustomTextField
                            placeholder={'Link'}
                            value={formLink}
                            disabled={true}
                        />
                        <FontAwesomeIcon 
                            className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
                            icon={copy ? faCheck : faCopy} 
                            title="Copiar para área de transferência" 
                            onClick={() => copy ? undefined : handleCopy()} 
                        />
                    </div>
                    
                    <div className='modalButtonWrapper'>
                        <AsyncButton
                            active={loading || isModalFieldsEmpty()}
                            text='Enviar'
                            className='PageModalButtonContainer'
                            onClick={handleSendMail}
                            loading={loading}
                        />

                        <AsyncButton
                            active={loading}
                            text='Fechar'
                            className='PageModalButtonContainer'
                            onClick={handleEmailModal}
                            loading={loading}
                        />
                    </div>
                </CustomModal>

            <FlagSelector/>
            <Sidebar activePage='Home'/>
    
            <div className='SideBarCompensation'/>
            
            <div className='CentralBlock'>
                <DocumentContainer/>
            </div>
        </div>
    )
}