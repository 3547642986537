import { useNavigate } from 'react-router-dom';
import { faAngleLeft, faFolder, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import './styles.css';

import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import FileUpload from '../../../components/FileUpload';
import { SearchBox } from '../../../components/SearchBox';
import { getStoredJson, randomNumericString, saveJsonData, sleep, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, editDatabaseRowsByColumn, genUUID, getDataBaseCondition, getDatabaseRows, getImagePaths, postApiData, sendFileDatabase, sendImageDatabase, url } from '../../../utils/database';
import { CustomModalPage } from '../../../components/Modal';
import { FileDocumentUpload } from '../../../components/FileUploadDocument';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';
import CustomModal from '../../../components/Modal';
import validator from 'validator';

export function PlantPage() {
	const [selectedPlant, setSelectedPlant] = useState<any>({})
	const [imageData, setImageData] = useState<any>([])
	const [fileData, setFileData] = useState<any>([])
	const [formInfo, setFormInfo] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})
	const [email, setEmail] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
	const [formLink, setFormLink] = useState<string>('')

	const navigate = useNavigate();
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language

	const [edit, setEdit] = useState(false)
	const [copy, setCopy] = useState(false)
	const [companies, setCompanies] = useState([])
	const [nextModalOpen, setNextModalOpen] = useState(false)
	const [emailModalOpen, setEmailModalOpen] = useState(false)
	const [emailWarning, setEmailWarning] = useState(false)
	const user = getStoredJson('user')

	useInitialCheck(initialCheck, [window.location.href])
	useInitialCheck(importCompanies)

	async function importCompanies() {
		let data = []

		if(user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}
	
	async function initialCheck() {
		const currentUrl: string = window.location.href
		const isEdit: boolean = currentUrl.indexOf('?edit') != -1
		const isInsert: boolean = currentUrl.indexOf('?insert') != -1
		const isModal: boolean = currentUrl.indexOf('?modal') != -1
		setEdit(isEdit)
		let response, data

		if (isModal)
			handleOpenNextModal()

		if (!isEdit && !isInsert)
			return

		data = getStoredJson(isEdit ? 'edit' : 'insert')

		if (data.company) {
			response = await getDataBaseCondition('companies', 'cnpj', data.company)
			data.company = response[0]
		}

		setImageData(await getImagePaths(data.img_path))
		//setFileData(await getImagePaths(data.doc_path))
		setSelectedPlant(data)

	}

	useEffect(() => {

	}, [emailModalOpen])

	async function handleEdit() {
		const edit = getStoredJson('edit')

		const newData = {
			plant_id: edit.plant_id,
			name: selectedPlant.name,
			location: selectedPlant.location,
			company: selectedPlant.company.cnpj,
			lat: selectedPlant.lat,
			long: selectedPlant.long,
			img_path: `img/plant/${selectedPlant.plant_id}`,
			doc_path: `doc/plant/${selectedPlant.plant_id}`
		}

		let response = await editDatabaseByCondition('plants', 'plant_id', edit.plant_id, newData)

		if (response.status != 'success')
			return alert('erro ao se conectar com o servidor')

		if (edit.company != newData.company) {
			response = await editDatabaseRowsByColumn('processes', 'company', edit.company, newData.company)
			response = await editDatabaseRowsByColumn('equipments', 'company', edit.company, newData.company)
			response = await editDatabaseRowsByColumn('inspections', 'company', edit.company, newData.company)
		}

		for (let file of fileData)
			await sendFileDatabase(file, newData.doc_path)

		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
			await sendImageDatabase(file, newData.plant_id, 'plant', x)
		}

		navigate(-1)
	}

	function handleNameChange(text: string) {
		setSelectedPlant({ ...selectedPlant, name: text })
	}

	function handleCompanyChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/Company?insert')

		setSelectedPlant({ ...selectedPlant, company: selectedOption })
	}

	async function handleSendMail(){
		if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		handleOpenNextModal()

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}

	useEffect(() => {
        if (formInfo && formInfo.id) {
            setFormLink(formInfo.id);
        }
    }, [formInfo]); 

	async function handleSubmit() {
		const uuid = genUUID()

		const plantData = {
			plant_id: uuid,
			name: selectedPlant.name,
			location: selectedPlant.location,
			company: selectedPlant.company.cnpj,
			lat: selectedPlant.lat,
			long: selectedPlant.long,
			img_path: `img/plant/${uuid}`,
			doc_path: `doc/plant/${uuid}`
		}

		const formData = {
			company: plantData.company,
			asset_id: plantData.plant_id
		}
		
		const response = await addDataBaseRow('plants', plantData)
		const response2 = await addDataBaseRow('forms', formData)

		if (response.status != 'success' || response2.status != 'success')
			return alert('erro ao adicionar')

		for (let file of fileData)
			await sendFileDatabase(file, plantData.doc_path)

		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
			await sendImageDatabase(file, uuid, 'plant', x)
		}
		
		saveJsonData('insert', {plant: plantData.plant_id, company: plantData.company})
		
		await sleep(200)

		let emailMsg = lang.emailMsg[currLang]
		setEmailMessage(emailMsg)
		let formLink = url.replace('api/?', '') + 'Forms?plant-' + uuid

		setFormLink(String(formLink))
		setEmailModalOpen(true)
	};

	const handleOpenNextModal = () => {
		setEmailModalOpen(false)
		setNextModalOpen(true)
	  };

	function handleManagement() {
		setGlobalOptions({ ...globalOptions, activeButton: 'Plant' })
		navigate('/Managment')
	}

	function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

	const handleEmailChange = (newEmail: any) => {
		setEmail(newEmail);  // Atualiza o estado do email com o novo valor
		setEmailWarning(false);  // Remove o aviso
	};

	function isFieldsEmpty() {
		const data = [
			selectedPlant?.name,
			selectedPlant?.company?.name,
			selectedPlant?.location
		]

		for (let value of data) {
			if (!value)
				return true

			if (value.trim().length == 0)
				return true
		}

		return false
	}

	function isModalFieldsEmpty() {
		if (!email)
			return true

		for (let value of email) {
			if (!value || value.trim().length === 0) 
				return true;
		}
	
		return false;
	}

	return (
		<div className="MainContainer">
			<CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
				<div className='textsModalContainer'>
					<h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
				</div>
				<div className='textsModalContainer'>
					<p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Email'}
						value={email}
						setValue={handleEmailChange}
					/>
				</div>
				{emailWarning === true && <p style={{ color: 'red'}}>Email inválido!</p>}
				
				<div className='modalInputText'>	
					<CustomTextField
						placeholder={'Mensagem'}
						value={emailMessage}
						setValue={setEmailMessage}
					/>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Link'}
						value={formLink}
						disabled={true}
						//navigator.clipboard.writeText(selectedPlant.name)
					/>
					<FontAwesomeIcon 
						className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
						icon={copy ? faCheck : faCopy} 
						title="Copiar para área de transferência" 
						onClick={() => copy ? undefined : handleCopy()} 
					/>
				</div>
				
				<div className='modalButtonWrapper'>
					<AsyncButton
						active={loading || isModalFieldsEmpty()}
						text='Enviar'
						className='PageModalButtonContainer'
						onClick={handleSendMail}
						loading={loading}
					/>

					<AsyncButton
						active={loading}
						text='Fechar'
						className='PageModalButtonContainer'
						onClick={handleOpenNextModal}
						loading={loading}
					/>
				</div>
			</CustomModal>

			<CustomModalPage
				title={'Planta Cadastrada'}
				subtitle={'Deseja Cadastrar um Processo?'}
				yesPage='/Process?insert'
				noPage='/Home'
				modal={nextModalOpen}
			/>
			
			<div className='SideBarCompensation'/>

			<div className="CentralBlock" style={{height: '100vh'}}>
				<FlagSelector />
				<Sidebar activePage='Register' />

				<div className='RegisterMainContainer'>
					<div className="iconContainer" style={{ width: '100%' }}>
						<div
							className='iconLeft'
							style={{ alignSelf: 'center', width: '7%' }}
							onClick={() => navigate(-1)}
						>

							<FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas" />
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
							<p className='clientText'>
								{edit ? lang.editPlant[currLang] : lang.registerPlant[currLang]}
							</p>

							<div className='folderContainer' onClick={handleManagement}>
								<FontAwesomeIcon icon={faFolder} title="Plantas já cadastradas" />
							</div>
						</div>
					</div>

					<div style={{ width: '85%' }}>
						<text className='equipmentText2'>{lang.plantInfo[currLang]}</text>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ width: '85%' }}>
						<CustomTextField
							placeholder={lang.name[currLang]}
							value={selectedPlant.name}
							setValue={handleNameChange}
						/>
					</div>

					<div style={{ padding: '.6rem' }} />

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<CustomDropBox
							options={companies}
							value={selectedPlant.company ? selectedPlant.company.name : ''}
							setValue={handleCompanyChange}
							placeholder={lang.company[currLang]}
							key='name'
							callbackKey={lang.addNew[currLang]}
						/>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<SearchBox
							value={selectedPlant.location ? selectedPlant.location : ''}
							title={lang.location[currLang]}
							dict={selectedPlant}
							setValue={setSelectedPlant}
						/>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<FileDocumentUpload
							fileData={fileData}
							setFileData={setFileData}
							title={lang.documents[currLang]}
						/>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<FileUpload
							title={lang.photos[currLang]}
							setImg={setImageData}
							img={imageData.length > 0 ? imageData[0] : null}
						/>
					</div>

					<AsyncButton
						active={loading || isFieldsEmpty()}
						text={edit ? lang.edit[currLang] : lang.register[currLang]}
						className='button-plant'
						onClick={
							edit 
							?
							async () => {setLoading(true); await handleEdit(); setLoading(false)}
							:
							async () => {setLoading(true); await handleSubmit(); setLoading(false)}
						}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	)
}
