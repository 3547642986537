import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';

const sxOptions = {
	width: '100%',
	height: '3.2rem',
	borderWidth: '15px',
	backgroundColor: '#fff',

	borderRadius: '1rem',
	'.MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, 1px) scale(0.8)',
		fontSize: '12px', // Ajusta o tamanho da fonte da label
	},
	'.MuiAutocomplete-listbox': {
		fontSize: '12px', // Ajusta o tamanho da fonte dos itens do dropdown    

	},
};

const sxTextFieldOptions = {
	'.MuiOutlinedInput-notchedOutline': {
		border: 'none',
		borderRadius: '1rem',

	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'.Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'.MuiInputBase-input': {
		fontSize: '12px', // Ajusta o tamanho da fonte do texto dentro do TextField
	},
	'.MuiInputLabel-outlined': {
		fontSize: '13px', // Ajusta o tamanho da fonte da label quando não está "shrinked"
		opacity: .5,

	},
};

export default function CustomDropBox({ options, value, setValue, placeholder, key = 'label', callbackKey = null }: any) {
	const [currValue, setCurrValue] = useState<string|null>(null)

	options = options.map((item: any) => ({ ...item, label: item.name }))

	if (callbackKey)
		options = [{ label: callbackKey, key: 'default' }, ...options]

	useEffect(() => { setCurrValue(value) }, [value])

	return (
		<Autocomplete
			disablePortal
			id="combo-box-demo"
			options={options}
			sx={sxOptions}
			value={currValue}
			onChange={(event, newValue) => { setCurrValue(newValue); setValue(newValue ? newValue : {}) }}
			noOptionsText='Não há dados cadastrados'
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
					sx={sxTextFieldOptions}
				/>
			)}
		/>
	);
}