import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faList, faFilter, faCamera, faWindowClose, faLayerGroup, faLocationDot, faQuestion, faHome} from "@fortawesome/free-solid-svg-icons";
import mapboxgl, { Marker } from 'mapbox-gl';

import "mapbox-gl/dist/mapbox-gl.css";
import "./styles.css";
import { sleep, useInterval } from "../../../utils";
import Sidebar from "../../../components/Sidebar";
import placeholder from "../../../assets/img/placeholder.png";
import { SearchBox } from "../../../components/SearchBox";
import lang from "./lang";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { getStoredJson, saveJsonData, useInitialCheck } from '../../../utils';
import { getDataBaseCondition, getDatabaseRows, getImagePaths } from '../../../utils/database';
import CustomDropBox from '../../../components/CustomDropBox';
import AsyncButton from '../../../components/AsyncButton';



const Map: React.FC = () => {
	mapboxgl.accessToken = "pk.eyJ1Ijoia2xhdXNzbWFyY2hpIiwiYSI6ImNsbGNsb245dzAyNXkzbm9iZzJ4emt2eWsifQ.HznsI_vVJ-lWe9swvbfT-Q";
	const {globalOptions, setGlobalOptions} = useGlobalContext()
	const currLang = globalOptions.language

	const user  = getStoredJson('user', {})
	const isAdm = (user.company == '123456789/0001')
	
	const map = useRef<mapboxgl.Map | null>(null);
	const mapContainer = useRef<HTMLDivElement | null>(null);
	const [mapReady, setMapReady] = useState<boolean>(false)

	const [lng, setLng] = useState<number>(-50.02);
	const [lat, setLat] = useState<number>(-24.79);
	const [zoom, setZoom] = useState<number>(9);
	const [previousCoords, setPreviousCoords] = useState({lat: 0, lng: 0})
	const navigate = useNavigate();
	const [selectedLocation, setSelectedLocation] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingBar, setLoadingBar] = useState(true)
	
	const [companies, setCompanies]  = useState<any>([]);
	const [plants, setPlants]  = useState<any>([]);
	const [processes, setProcesses]  = useState<any>([]);
	const [equipments, setEquipments]  = useState<any>([]);
	
	const [selectedCompany, setSelectedCompany]  = useState<any>([]);
	const [selectedPlant, setSelectedPlant]  = useState<any>([]);
	const [selectedProcess, setSelectedProcess]  = useState<any>([]);
	const [selectedEquipment, setSelectedEquipment]  = useState<any>([]);

	const [showFloor, setShowFloor] = useState<boolean>(false)
	const [numFloors, setNumFloors] = useState<number>(0)
	const [filterBar, setFilterBar]  = useState<boolean>(false)

	const [markersSub, setMarkersSub]  = useState<boolean>(false);
	const [mapStyleWrapper, setMapStyleWrapper]  = useState<boolean>(false);
	const [mapStyle, setMapStyle] = useState<string>(localStorage.getItem('mapStyle') || "mapbox://styles/mapbox/streets-v12");
	
	const [markerData, setMarkerData] = useState<any>({})
	const [processMarkers, setProcessMarkers] = useState<any>([])
	const [equipmentMarkers, setEquipmentMarkers] = useState<any>([])
	const [showMarkers, setShowMarkers] = useState<boolean>(false)
	const [companyImage, setCompanyImage] = useState<any>(null)

	const floorOptions = [
		{label: 'Todos', value: 0},
		{label: '1', value: 1},
		{label: '2', value: 2},
		{label: '3', value: 3},
	]
	
	useInitialCheck(importInfo)
	useInitialCheck(storageSetup, [mapStyle])
	useInitialCheck(firstPlot, [mapReady])
	useInitialCheck(createPopup, [markerData])
	useInterval(handleSearchCenter, 300)
	useInitialCheck(handleShowMarkers, [showMarkers])
	useInitialCheck(handleUpdateFloors, [numFloors])

	useEffect(() => {
		const handleMapZoom = (event: CustomEvent) => {
			if (map.current && event.detail.coordinates) {
				map.current.flyTo({
					center: event.detail.coordinates,
					zoom: event.detail.zoomLevel,
					essential: true,
				});
			}
		};

		window.addEventListener("mapZoom", handleMapZoom as EventListener);
		return () => {window.removeEventListener("mapZoom", handleMapZoom as EventListener)}
	}, []);

	async function handleShowMarkers() {
		if(showMarkers){
			applyProcessMarkers()
			applyEquipmentMarkers()
			return
		}

		processMarkers.forEach((marker: any) => showMarkers ? marker.addTo(map.current) : marker.remove());
		equipmentMarkers.forEach((marker: any) => showMarkers ? marker.addTo(map.current) : marker.remove());
	}

	async function handleUpdateFloors() {
		processMarkers.forEach((marker: any) => marker.remove())
		equipmentMarkers.forEach((marker: any) =>  marker.remove())
		applyProcessMarkers()
		applyEquipmentMarkers()
	}

	async function importCompanies(){
		let response = null

		if(isAdm){
			response = await getDatabaseRows('companies') 
		}
		else{
			response = await getDataBaseCondition('companies', 'cnpj', user.company)

			if(response.length > 0)
				setCompanyValue(response[0])
		}

        return setCompanies(response)	
    }

	async function importAllowedData(tableName: string, setData: any){
		let response = []

		if(isAdm)
			response = await getDatabaseRows(tableName)
		else
			response = await getDataBaseCondition(tableName, 'company', user.company)

		return setData(response)
	}

	async function importInfo() {
		await importCompanies()
		await importAllowedData('plants', setPlants)
		await importAllowedData('processes', setProcesses)
		await importAllowedData('equipments', setEquipments)
		setMapReady(true)
	}

	async function storageSetup() {
		localStorage.setItem('mapStyle', mapStyle);
	}
	
	function applyPlantMarkers(){
		let markers = []

		for (let x = 0; x < plants.length; x++) {
			const plant = plants[x]

			if(!plant.lat || !plant.long)
				continue
			
			markers.push({
				target: 'plant',
				long: plant.long,
				lat: plant.lat,
				location: plant.location,
				name: plant.name,
				plant_id: plant.plant_id,
				doc_path: plant.doc_path,
				img_path: plant.img_path,
				company: plant.company,
			});
		}

		const markerStyle  = {
			color: 'red',
			draggable: false,
			clickTolerance: 5,
		} 

		for (let x = 0; x < markers.length; x++) {
			const actual: any = markers[x];
			const coords: any = [markers[x].long, markers[x].lat]

			if(!map.current)
				continue
			
			const marker = new mapboxgl.Marker(markerStyle).setLngLat(coords).addTo(map.current);
			marker.getElement().style.cursor = 'pointer';

			marker.getElement().addEventListener("click", (event) => {
				event.stopPropagation();
				event.preventDefault();
				handlePopupInfo(actual, marker);
			});
			
			if (markers.length == 0)
				continue

			changeMapCenter(markers[0].lat, markers[0].long, 12);
		}
	}

	function applyProcessMarkers(){
		let markers = []

		for (let x = 0; x < processes.length; x++) {
			const process = processes[x]
			
			if(numFloors == 0 || numFloors == process.floor)
				markers.push({
					target: 'process',
					long: process.long,
					lat: process.lat,
					name: process.name,
					process_id: process.process_id,
					plant: process.plant,
					doc_path: process.doc_path,
					img_path: process.img_path,
				})
		}

		const markerStyle  = {
			color: 'yellow',
			draggable: false,
			clickTolerance: 5,
			scale: .75
		}

		markers = markers.map(actual => {
			const marker = new mapboxgl.Marker(markerStyle).setLngLat([actual.long, actual.lat]);
			marker.getElement().style.cursor = 'pointer';

			marker.getElement().addEventListener("click", (event) => {
				event.stopPropagation();
				event.preventDefault();
				handlePopupInfo(actual, marker);
			});				

			if (map.current)
				marker.addTo(map.current); 

			return marker;
		})

		setProcessMarkers(markers)
	}

	function applyEquipmentMarkers(){
		let markers = []
		
		for(let x = 0; x < equipments.length; x++){
			const equipment = equipments[x]

			if(numFloors == 0 || numFloors == equipment.floor)
				markers.push({
					target: 'equipment',
					long: equipment.long,
					lat: equipment.lat,
					name: equipment.name,
					equipment_id: equipment.equipment_id,
					process: equipment.process,
					doc_path: equipment.doc_path,
					img_path: equipment.img_path,
				})
		}

		const markerStyle  = {
			color: 'blue',
			draggable: false,
			clickTolerance: 25,
			scale: .60
		}
		
		markers = markers.map(actual => {
			const marker = new mapboxgl.Marker(markerStyle).setLngLat([actual.long, actual.lat]);
			marker.getElement().style.cursor = 'pointer';

			marker.getElement().addEventListener("click", (event) => {
				event.stopPropagation();
				event.preventDefault();
				handlePopupInfo(actual, marker);
			});			

			if (map.current)
				marker.addTo(map.current); 

			return marker;
		})

		setEquipmentMarkers(markers)
	}

	function renderImage2(img2: any): HTMLDivElement {
		const div = document.createElement("div");

		if (img2 == null) {
			const placeholderImage = document.createElement("img");
			placeholderImage.className = "placeholder_Map";
			placeholderImage.src = placeholder;
			placeholderImage.alt = "";
			div.appendChild(placeholderImage);
			return div
		}

		const logoImage2 = document.createElement("img");
		logoImage2.className = "plantLogo";
		logoImage2.src = img2;
		logoImage2.alt = "";
		div.appendChild(logoImage2);
		return div;
	}

	function renderCameraIcon(img: any, width: string, height: string){
        if(!img || img == '')
            return (
                <div className='cameraContainer' style={{width: width, height: height}}>
                    <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
                </div>
            )
        
        return (<img className='cameraContainer' style={{width: width, height: height}} src={img} alt=''/>)
    }

	async function changeMapCenter(newLat: number, newLng: number, newZoom?: number) {
		await sleep(100);
		setLat(newLat);
		setLng(newLng);
		setPreviousCoords({lat: newLat, lng: newLng})

		if(newZoom){
			if(map.current)
				map.current.flyTo({center: [newLng, newLat], essential: true, zoom: newZoom})

			return
		}
		
		if (map.current)
			map.current.flyTo({center: [newLng, newLat], essential: true})
	}

	function handleSearchCenter() {
		if (!selectedLocation.lat) 
			return;
		
		changeMapCenter(selectedLocation.lat, selectedLocation.long, selectedLocation.zoom);
		setSelectedLocation({})
	}
	
	async function handlePopupInfo(actual: any, marker: any){
		if(marker.getPopup())
			marker.getPopup().remove();	

		let response: any

		response = await getImagePaths(actual.img_path)
		const pic = response.length > 0 ? response[0] : null

		if(actual.target == 'plant')
			response = await getDataBaseCondition('companies', 'cnpj', actual.company)
		else if(actual.target == 'process')
			response = await getDataBaseCondition('plants', 'plant_id', actual.plant)
		else if(actual.target == 'equipment')
			response = await getDataBaseCondition('processes', 'process_id', actual.process)

		const father = response.length > 0 ? response[0].name : 'N/A'
		setMarkerData({marker: marker, actual: actual, pic: pic, father: father})
	}

	async function createPopup(){
		const actual = markerData.actual
		const marker = markerData.marker

		if(!actual || !marker)
			return
		
		if (marker.getPopup()) 
        	marker.getPopup().remove();
		
		changeMapCenter(actual.lat, actual.long, actual.target == 'plant' ? 18 : 25);
		
		const div = document.createElement("div")
		div.classList.add("marker-popup")

		div.onclick = function() {
			if(actual.target == 'equipment'){
				saveJsonData('equipmentInfo', actual)
				return navigate('/Document')
			}

			saveJsonData('searchRegion', actual)
			navigate("/Region")
		};

		const imageDiv = document.createElement("div")
		imageDiv.appendChild(renderImage2(markerData.pic));
		div.appendChild(imageDiv);

		const wrapperDiv = document.createElement("div");
		wrapperDiv.classList.add("wrapper_Map");
		div.appendChild(wrapperDiv);

		const wrapperParagraph = document.createElement("div");
		wrapperParagraph.classList.add("wrapper_Paragraph");
		wrapperDiv.appendChild(wrapperParagraph);

		const paragraph = document.createElement("p");
		paragraph.textContent = `${actual.name}`;
		paragraph.classList.add("popup-text");
		wrapperDiv.appendChild(paragraph);

		const paragraphSub = document.createElement("p");
		paragraphSub.textContent = markerData.father
		paragraphSub.classList.add("popup-subtext");
		wrapperDiv.appendChild(paragraphSub);

		if(!map.current)
			return

		const popup = new mapboxgl.Popup({offset: { bottom: [0, +40] }})
					  .setDOMContent(div)
					  .setLngLat([actual.long, actual.lat])
					  .addTo(map.current);

		marker.setPopup(popup);
		popup.addTo(map.current);
	}
	
	async function firstPlot() {
		if(!mapReady)
			return

		if (!map.current){
			map.current = new mapboxgl.Map({
				container: mapContainer.current!,
				style: mapStyle,
				center: [lng, lat],
				zoom: zoom,
			});
			
			map.current.on("move", () => {
				setLng(parseFloat(map.current!.getCenter().lng.toFixed(4)));
				setLat(parseFloat(map.current!.getCenter().lat.toFixed(4)));
				setZoom(parseFloat(map.current!.getZoom().toFixed(2)));
			});

			map.current.on("zoom", () => {			
				if (!map.current)
					return 

				setLng(parseFloat(map.current.getCenter().lng.toFixed(4)));
				setLat(parseFloat(map.current.getCenter().lat.toFixed(4)));
				setZoom(parseFloat(map.current.getZoom().toFixed(2)));

				const newZoom = map.current.getZoom()
				setShowMarkers(newZoom > 15 && !showMarkers)
			})
			
			setLoadingBar(false)
		}

		applyPlantMarkers()
	}

	function handleFilterBar() {
		setFilterBar(!filterBar)
		return
	}

	function handleMapWrapper() {
		setMapStyleWrapper(!mapStyleWrapper)
		return
	}

	function handleMarkersWrapper() {
		setMarkersSub(!markersSub)
		return
	}

	function handleMapStyle(stl: string) {
		setMapStyle(stl)
		window.location.reload();
	}

	async function setCompanyValue(selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {})
            return navigate('/Company?insert')
        }

		setSelectedPlant({})
		setSelectedProcess({})
		setSelectedEquipment({})
		setSelectedCompany(selectedOption)

		const images = await getImagePaths(selectedOption.img_path)
        setCompanyImage(images.length > 0 ? images[0] : null)
    }

	async function setPlantValue (selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {company: selectedCompany.cnpj})
            return navigate('/Plant?insert')
        }

		setSelectedProcess({})
		setSelectedEquipment({})
		setSelectedPlant(selectedOption)
	}

	function setProcessValue (selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {company: selectedCompany.cnpj, plant: selectedPlant.plant_id})
            return navigate('/Process?insert')
        }

		setSelectedEquipment({})
        setSelectedProcess(selectedOption)
    }; 

	function setEquipmentValue (selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {company: selectedCompany.cnpj, plant: selectedPlant.plant_id, process: selectedEquipment.equipment_id})
            return navigate('/Equipment?insert')
        }

        setSelectedEquipment(selectedOption)
    }; 

	async function handleSearch() {
		const lat  = (selectedEquipment.name) ? selectedEquipment.lat :
				     (selectedProcess.name)   ? selectedProcess.lat   : selectedPlant.lat 

		const long = (selectedEquipment.name) ? selectedEquipment.long :
				     (selectedProcess.name)   ? selectedProcess.long   : selectedPlant.long 

		const newZoom = (selectedProcess.name || selectedEquipment.name) ? 20 : 12

		const newLocation = { 
			location: selectedPlant.location, 
			lat: lat, 
			long: long,
			zoom: newZoom
		}

		setSelectedLocation(newLocation)
		await sleep(3500)
	}

	async function handleFloorClick(floor: number) {
		setNumFloors(floor)
		setShowFloor(false)
	}

	return (
		<div
			ref={mapContainer}
			className="map-container"
			style={{ width: "100%", height: "100%" }}
		>	
			{filterBar && (
				<div className="filterBar_map">
					<div>
					<FontAwesomeIcon
						icon={faWindowClose}
						className="icon_filterBar"
						onClick={() => handleFilterBar()}
					/>
					</div>

					<div className="cmplogo_filterBar">
						{renderCameraIcon(companyImage, '13vw', '80%')}
						<div className="cmpname_filterBar">{selectedCompany.name}</div>
					</div>

					<div className="dropboxwrapper_filterBar">
						{isAdm && (
							<div className="dropbox_filterBar">
								<CustomDropBox
									options={companies}
									value={selectedCompany.name ? selectedCompany.name : ''}
									setValue={setCompanyValue}
									placeholder={lang.company[currLang]}
									key='name'
								/>
							</div>
						)}

						<div className="dropbox_filterBar">
							<CustomDropBox
								options={plants.filter((item: any) => item.company == selectedCompany.cnpj)}
								value={selectedPlant.name ? selectedPlant.name : ''}
								setValue={setPlantValue}
								placeholder={lang.plant[currLang]}
								key='name'
							/>
						</div>

						<div className="dropbox_filterBar">
							<CustomDropBox
								options={processes.filter((item: any) => item.plant == selectedPlant.plant_id)}
								value={selectedProcess.name ? selectedProcess.name : ''}
								setValue={setProcessValue}
								placeholder={lang.process[currLang]}
								key='name'
							/> 
						</div>

						<div className="dropbox_filterBar">
							<CustomDropBox
								options={equipments.filter((item: any) => item.process == selectedProcess.process_id)}
								value={selectedEquipment.name ? selectedEquipment.name : ''}
								setValue={setEquipmentValue}
								placeholder={lang.equipment[currLang]}
								key='name'
							/> 
						</div>
					</div>
					<div className="searchbutton_filterBar">
						<div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
							<AsyncButton
								active={loading}
								text={lang.search[currLang]}
								className='button-process'
								onClick={ async () => {setLoading(true); await handleSearch(); setLoading(false)}}
								loading={loading}
							/>
						</div>
					</div>
					
				</div>
			)}
			{!filterBar && (
				<>
				<div className="search-wrapper_Map">
					<div className="searchBox_map">
						<SearchBox
							setValue={setSelectedLocation}
							styleClass="search"
							optionClass="option1"
							placeholder="Localização"
							value={selectedLocation.location ? selectedLocation.location : ""}
						/>

						<FontAwesomeIcon
							icon={faFilter}
							className="iconfilter_Map"
							onClick={() => handleFilterBar()}
							style={{ position: 'absolute', top: '40%', right: '5%', zIndex: 1000 }}
						/>
					</div>
				</div>

				<div className="sub-wrapper_map">
					<FontAwesomeIcon
						icon={faLocationDot}
						className="iconmarker_Map"
						onClick={() => handleMapWrapper()}
					/>
				</div>

				{mapStyleWrapper && (
					<div className="map-style-wrapper" style={{flexDirection: 'column', left: '93.2%'}}>
						<div className="map-style" onClick={() => handleMapStyle("mapbox://styles/mapbox/streets-v12")}>{lang.streets[currLang]}</div>
						
						<div className="map-style" onClick={() => handleMapStyle("mapbox://styles/mapbox/satellite-streets-v12")}>{lang.satellite[currLang]}</div>
					</div>
				)}

				{!mapStyleWrapper && (
					<div className="sub-wrapper_map" style={{top: '8.5%'}}>
						<FontAwesomeIcon
							icon={faQuestion}
							className="iconmarker_Map"
							onClick={() => {handleMarkersWrapper(); setShowFloor(false)}}
						/>
					</div>
				)}

				{markersSub && !mapStyleWrapper && (
					<div className="marker-wrapper" style={{top: '4.5%'}}>
						<div className="subtitle-marker">
							<FontAwesomeIcon
								icon={faLocationDot}
								className="iconmarker_Map"
								onClick={() => handleMarkersWrapper()}
								style={{fontSize: '1rem', color: 'red'}}
							/>
							<div>{lang.plant[currLang]}</div>
						</div>

						<div className="subtitle-marker">
							<FontAwesomeIcon
								icon={faLocationDot}
								className="iconmarker_Map"
								onClick={() => handleMarkersWrapper()}
								style={{fontSize: '1rem', color: 'yellow'}}
							/>
							<div>{lang.process[currLang]}</div>
						</div>

						<div className="subtitle-marker">
							<FontAwesomeIcon
								icon={faLocationDot}
								className="iconmarker_Map"
								onClick={() => handleMarkersWrapper()}
								style={{fontSize: '1rem', color: 'blue'}}
							/>
							<div>{lang.equipment[currLang]}</div>
						</div>
					</div>
				)}


				{/*FLOORS*/}
				
				{!mapStyleWrapper && (
					<div className="sub-wrapper_floor" title='andares' onClick={() => setShowFloor(prev => !prev)}>
						{numFloors == 0 ? (
								<FontAwesomeIcon
									icon={faLayerGroup}
									className="iconmarker_Map"
								/>
							) : (<div className="iconmarker_Map">{numFloors}</div>)
						}
					</div>
				)}

				{showFloor && (
					<div 
						className="marker-wrapper_floor" 
						style={{top: '10%', left: '92%', width: '5rem'}}
					>
						{floorOptions.map((item: any, index: number) => (
							<div 
								className="subtitle-marker"
								style={{cursor: 'pointer'}}
								onClick={() => handleFloorClick(item.value)}
							>
								<FontAwesomeIcon
									icon={faLocationDot}
									className="iconmarker_Map"
									style={{fontSize: '1rem', color: 'black'}}
								/>
								
								<div>{item.label}{item.value == 0 ? ' ' : 'º ' + lang.floor[currLang]}</div>
							</div>
						))}
					</div>
				)}
				
				<div className="CenterHome" onClick={() => changeMapCenter(previousCoords.lat, previousCoords.lng)}>
					<FontAwesomeIcon icon={faHome} className="CenterHomeIcon"/>
				</div>

				<div className="list_Map" onClick={() => navigate("/List")}>
					{lang.list[currLang]}
					<FontAwesomeIcon icon={faList} className="icon_Map" />
				</div>
				</>
			)}

			{loadingBar && (
				(<div className='loading-circle-splash' style={{top: '50%', left: '55%', zIndex: '100', position: 'absolute'}} />)
			)}
			
		</div>
	);
};

export function MapPage() {
	return (
		<div className="mapContainer">
			<Sidebar
				customStyle={{ backgroundColor: "rgba(56, 54, 54, 0.9)" }}
				activePage="Search"
			/>
			
			<Map />
		</div>
	);
}
