import { useNavigate } from 'react-router-dom';
import { faAngleLeft, faFolder, faCopy, faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import './styles.css';

import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import FileUpload from '../../../components/FileUpload';
import { SearchBox } from '../../../components/SearchBox';
import { getStoredJson, saveJsonData, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, editDatabaseRowsByColumn, genUUID, getDataBaseCondition, getDatabaseRows, getImagePaths, sendFileDatabase, sendImageDatabase } from '../../../utils/database';
import { CustomModalPage } from '../../../components/Modal';
import { FileDocumentUpload } from '../../../components/FileUploadDocument';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';
import CustomModal from '../../../components/Modal';

export function FormsPage() {
	const [selectedPlant, setSelectedPlant] = useState<any>({})
	const [imageData, setImageData] = useState<any>([])
	const [fileData, setFileData] = useState<any>([])
	const [formID, setFormID] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [filledForm, setFilledForm] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>({})

	const navigate = useNavigate();
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language

	const [isPlant, setIsPlant] = useState(false)
	const [isProcess, setIsProcess] = useState(false)
	const [isEquipment, setIsEquipment] = useState(false)

	const [edit, setEdit] = useState(false)
	const [copy, setCopy] = useState(false)
	const [companies, setCompanies] = useState([])
	const [modalOpen, setModalOpen] = useState(false)
	const [notAllowed, setNotAllowed] = useState(false)
	const [emailModalOpen, setEmailModalOpen] = useState(false)
	const user = getStoredJson('user')

	interface SelectedOption {
        name?: string;
        value?: number;
        justify?: string;
    }

    const plantAge = [
		{ name: `< 10 ${lang.years[currLang]}`, value: '< 10' },
		{ name: `10 - 15 ${lang.years[currLang]}`, value: '10 - 15' },
		{ name: `16 - 20 ${lang.years[currLang]}`, value: '16 - 20' },
		{ name: `21 - 25 ${lang.years[currLang]}`, value: '21 - 25' },
		{ name: `> 25 ${lang.years[currLang]}`, value: '> 25' },
    ];

    const generic = [
        { name: `${lang.yes[currLang]}`, value: 1, justify: ''},
		{ name: `${lang.no[currLang]}`, value: 2, justify: ''},
    ]

	const specificOptions = [
		{ name: `${lang.galvanizedSteel[currLang]}`, value: 2, justify: ''},
		{ name: `${lang.stainlessSteel[currLang]}`, value: 3, justify: ''},
		{ name: `${lang.carbonSteel[currLang]}`, value: 4, justify: ''},
		{ name: `${lang.otherMaterial[currLang]}`, value: 1, justify: ''},
	]

	const plantQuestions = [
			'eqpAdd', 
			'acc', 
			'coatingType', 
			'lastPainting', 
			'tfm', 
			'historicalData', 
			'safetyProblems', 
			'repairFactors', 
			'thermalInsulation', 
			'chemicalsAffectingCoating'
	]	

	const equipmentQuestions = [
			'newEquipmentAdded', 
			'ACCCondition', 
			'coatingSystem',  
			'lastMaintenance', 
			'TFMBeforeFailure', 
			'reasonForFailure', 
			'historicalCoatingData', 
			'equipmentFailureDueToCorrosion', 
			'safetyIncidents',
			'timeSinceFailure',
			'repairMaintenanceFactors',
			'thermalInsulationOnEquipment',
			'insulationSurfaceInspection',
			'lastInspectionDate',
			'identifyThermalSystems',
			'damagedInsulationMaterial',
			'equipmentInUse',
			'thermalTracing',
			'mixedMaterials',
			'chemicalsAffectingEquipmentCoating',
			'acidFumes',
]	

	useInitialCheck(initialCheck, [window.location.href])
	
	async function initialCheck() {
		const currentUrl: string = window.location.href
		const isEdit: boolean = currentUrl.indexOf('?edit') != -1
		const isPlt: boolean = currentUrl.indexOf('?plant') != -1
		const isPrc: boolean = currentUrl.indexOf('?process') != -1
		const isEqp: boolean = currentUrl.indexOf('?equipment') != -1

		let match 
		if (isPlt)
			match = currentUrl.match(/plant-([0-9a-fA-F\-]+)/);
		else if (isPrc)
			match = currentUrl.match(/process-([0-9a-fA-F\-]+)/)
		else if(isEqp)
			match = currentUrl.match(/equipment-([0-9a-fA-F\-]+)/)
		else{
			setNotAllowed(true)
			setModalOpen(true)
			return
		}
		
		const actualID = match ? match[1] : '';

		setFormID(actualID)
		setEdit(isEdit)
		setIsPlant(isPlt)
		setIsProcess(isPrc)
		setIsEquipment(isEqp)

		let formResponse = await getDataBaseCondition('forms', 'asset_id', actualID)
		
		//alert(JSON.stringify(formResponse))
		//alert(JSON.stringify(actualID))


		if(formResponse.length == 0){
			setNotAllowed(true)
			setModalOpen(true)
			return
		}
			

		if(formResponse[0].form_data){
			setFilledForm(true)
			setModalOpen(true)
		}
			
	}

	function handlePlantAgeChange(selectedOption: any) {
		setFormData({ ...formData, plantAge: selectedOption })
	}

	const handleSteelUpdate = (update: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            specific: {
                ...prevFormData.specific,
                ...update
            }
        }));
    };
    
    function handleGenericUpdate (name: any, update: any) {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			[name]: {
				...prevFormData[name],
				...update
			}
		}));
	};

	async function handleSubmit() {
        //alert(JSON.stringify(formData))
        //return
		const finalFormData = {
			form_data: JSON.stringify(formData)
		}
		
		const response = await editDatabaseByCondition('forms', 'asset_id', formID, finalFormData)

		if (response.status != 'success')
			return alert('erro ao adicionar')
		window.location.reload();
	};

	function isFieldsEmpty() {
		let data;
		if (!isEquipment) {
			data = [
				formData?.plantAge,
				formData?.eqpAdd,
				formData?.acc,
				formData?.coatingType,
				formData?.lastPainting,
				formData?.tfm,
				formData?.historicalData,
				formData?.safetyProblems,
				formData?.repairFactors,
				formData?.thermalInsulation,
				formData?.chemicalsAffectingCoating,
				formData?.specific,
			]
		} else {
			data = [
				formData?.plantAge,
				formData?.newEquipmentAdded,
				formData?.ACCCondition,
				formData?.coatingSystem,
				formData?.lastMaintenance,
				formData?.TFMBeforeFailure,
				formData?.reasonForFailure,
				formData?.historicalCoatingData,
				formData?.equipmentFailureDueToCorrosion,
				formData?.safetyIncidents,
				formData?.timeSinceFailure,
				formData?.repairMaintenanceFactors,
				formData?.thermalInsulationOnEquipment,
				formData?.insulationSurfaceInspection,
				formData?.lastInspectionDate,
				formData?.identifyThermalSystems,
				formData?.damagedInsulationMaterial,
				formData?.equipmentInUse,
				formData?.thermalTracing,
				formData?.mixedMaterials,
				formData?.chemicalsAffectingEquipmentCoating,
				formData?.acidFumes,
				formData?.specific,
			]
		}
		

		for (let option of data) {
			//alert(JSON.stringify(value))
			if (!option)
				return true

			if (option.length == 0)
				return true

			if(option.value == 1 && option.justify == '')
				return true
				
		}

		return false
	}

	return (
		<div className="MainContainer">	
		<CustomModal modalOpen={modalOpen} className='modalContainer'>
			<div className={notAllowed ? 'deniedModal' : 'circleCheckModal'}>
				<FontAwesomeIcon icon={notAllowed ? faCircleXmark : faCircleCheck} style={{color: 'white', fontSize: '2rem'}}/>
			</div>

			<div className='textsModalContainer'>
				<p className='warningModalTextContainer'>{notAllowed ? lang.deniedForm[currLang] : lang.succeededForm[currLang]}</p>
			</div>
		</CustomModal>	

			<div className="CentralBlock" style={{height: '100vh'}}>
				<FlagSelector />
				
				<div className='RegisterMainContainer'>
					<div className="iconContainer" style={{ width: '100%' }}>
						<div
							className='iconLeft'
							style={{ alignSelf: 'center', width: '7%' }}
						>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
							<p className='clientText'>
								{isProcess ? lang.registerFormProcess[currLang] : isEquipment ? lang.registerFormEquipment[currLang] : lang.registerFormPlant[currLang]}
							</p>
						</div>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                    <p className='questionText'>
                        {isProcess ? lang.processAge[currLang] : isEquipment ? lang.equipmentAge[currLang] : lang.plantAge[currLang]}
					</p>
						<CustomDropBox
							options={plantAge}
							value={formData.plantAge ? formData.plantAge.name : ''}
							setValue={handlePlantAgeChange}
							placeholder=''
							key='name'
						/>
					</div>

					{isEquipment ? (
						equipmentQuestions.map((key: any) => (
							<div key={key} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
								<p className='questionText'>
								{lang[key][currLang]}
								</p>
								<CustomDropBox
									options={generic}
									value={formData[key] ? formData[key] : ''}
									setValue={(selectedOption: SelectedOption) => handleGenericUpdate(key, selectedOption)}
									placeholder=''
									key='name'
								/>
								{formData[key] && formData[key].value == 1 && (
								<>
									<div style={{ padding: '.3rem' }} />
										<CustomTextField
											placeholder={lang.specify[currLang]}
											value={fileData[key]}
											setValue={(text: string) => handleGenericUpdate(key, {justify: text})}
										/>
								</>
								)}
							</div>
						))
					)

					:

					(
						plantQuestions.map((key: any) => (
							<div key={key} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
								<p className='questionText'>
								{lang[key][currLang]}
								</p>
								<CustomDropBox
									options={generic}
									value={formData[key] ? formData[key] : ''}
									setValue={(selectedOption: SelectedOption) => handleGenericUpdate(key, selectedOption)}
									placeholder=''
									key='name'
								/>
								{formData[key] && formData[key].value == 1 && (
								<>
									<div style={{ padding: '.3rem' }} />
										<CustomTextField
											placeholder={lang.specify[currLang]}
											value={fileData[key]}
											setValue={(text: string) => handleGenericUpdate(key, {justify: text})}
										/>
								</>
								)}
							</div>
						))
					)
					}

					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
						<p className='questionText'>
							{isEquipment ? lang.materialUsed[currLang] : lang.installationConstruction[currLang]}
						</p>
						<CustomDropBox
							options={specificOptions}
							value={formData.specific ? formData.specific.name : ''}
							setValue={handleSteelUpdate}
							placeholder=''
							key='name'
						/>
						{formData.specific && formData.specific.value === 1 && (
							<>
								<div style={{ padding: '.3rem' }} />
								<CustomTextField
									placeholder={lang.specify[currLang]}
									value={fileData}
									setValue={(text: string) => handleSteelUpdate({justify: text})}
								/>
							</>
						)}
					</div>

					<div style={{padding: '1rem'}}></div>

					<AsyncButton
						active={loading || isFieldsEmpty()}
						text={lang.register[currLang]}
						className='button-plant'
						onClick={async () => {setLoading(true); await handleSubmit(); setLoading(false)}}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	)
}
