import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlagSelector from '../../../components/FlagSelector'
import Sidebar from '../../../components/Sidebar'
import { useGlobalContext } from '../../../contexts/GlobalContext'
import lang from './lang'
import './styles.css'
import { faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import CustomDropBox from '../../../components/CustomDropBox'
import { useState } from 'react'
import { getDataBaseCondition, getDatabaseRows } from '../../../utils/database'
import { sleep, useInitialCheck } from '../../../utils'
import ScrollView from '../../../components/ScrollView'
import { Table, TableRow, TableCell } from '../../../components/Table'
import CustomPieChart from '../../../components/Graphs/CustomPieChart'
import CustomBarChart from '../../../components/Graphs/CustomBarChart'


export function CorrosionPage(){
    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()

    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showData, setShowData] = useState(false)

    const [companies, setCompanies] = useState<any>([])
    const [plants, setPlants] = useState<any>([])
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedPlant, setSelectedPlant] = useState<any>({})

    const [inspections, setInspections] = useState<any>([])
    const [processes, setProceses] = useState<any>([])
    const [equipments, setEquipments] = useState<any>([])

    useInitialCheck(importCompanies)
    useInitialCheck(importPlants, [selectedCompany])
    useInitialCheck(handleDataChange, [selectedCompany, selectedPlant])
    
    async function importCompanies(){
        const response = await getDatabaseRows('companies')
        setCompanies(response)
    }

    async function importPlants(){
        if(!selectedCompany.name)
            return

        const response = await getDataBaseCondition('plants', 'company', selectedCompany.cnpj)
        setPlants(response)
    }

    function handleDataChange(){
        if(!selectedCompany.name || !selectedPlant.name){
            setActive(false)
            setShowData(false)
            return
        }

        setActive(true)
    }

    async function handleButtonClick(){
        await sleep(700)
        let response
        
        response = await getDataBaseCondition('inspections', 'plant', selectedPlant.plant_id)
        setInspections(response)
        
        response = await getDataBaseCondition('equipments', 'plant', selectedPlant.plant_id)
        setEquipments(response)

        response = await getDataBaseCondition('processes', 'plant', selectedPlant.plant_id)
        setProceses(response)
        setShowData(true)
    }

    function renderGraphs(){
        let rows: any = []

        for(let i=0; i<processes.length; i+=4){
            let columns: any = []

            for(let j=0; j<4; j++){
                if(i+j >= processes.length)
                    continue
                
                const process = processes[i+j]
                const targetEquipments = equipments.filter((item: any) => item.process == process.process_id)

                let targetInspections: any = []
                for(let equip of targetEquipments){
                    const target = inspections.filter((item: any) => (item.equipment == equip.equipment_id) && (item.approved == 1))
                    targetInspections = [...targetInspections, ...target]
                }
                
                const lastInspection = getLastInspection(targetInspections)
                const lastRelatory   = lastInspection ? JSON.parse(lastInspection.relatory) : null 
                const corrosion      = lastRelatory   ? lastRelatory?.SubstrateEvaluation?.corrosion + '%' : '0%' 

                const targetReports = targetInspections.map((item: any) => JSON.parse(item?.relatory))
                const sumS4   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity?.includes('S4')).length
                const sumS3   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity?.includes('S3')).length
                const sumS2   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity?.includes('S2')).length
                const allNull = (sumS4 == 0) && (sumS2 == 0) && (sumS3 == 0)
                
                let data: any = []

                if(sumS4 != 0)
                    data.push({name: 'S4 (SEVERA)', value: sumS4, color: 'orange'})
                
                if(sumS3 != 0)
                    data.push({name: 'S3 (MODERADA)', value: sumS3, color: '#FF8C00'})
                
                if(sumS2 != 0)
                    data.push({name: 'S2 (REGULAR)', value: sumS2, color: '#Ffa500'})

                columns.push(
                    <div className='column'>
                        <div style={{color: 'white'}}>
                            {corrosion}
                        </div>

                        <div style={{padding: '.7rem'}}/>

                        <div style={{height: '100%', width: '100%'}}>
                            <CustomPieChart
                                data={data}
                                hasData={!allNull}
                            />
                        </div>

                        <div style={{padding: '.7rem'}}/>

                        <div style={{color: 'white'}}>
                            {process.name}
                        </div>
                    </div>
                )
            }
            
            rows.push(
                <div className='row'>
                    {columns}
                </div>
            )
        }

        return (
            <div className='GraphsContainer'>
                {rows.map((row: any, index: number) => (
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                        {row}
                        {(index == rows.length - 1) ? <div/> : <div className='LineRow'/>}
                    </div>
                ))}
            </div>
        )
    }

    function getLastInspection(data: any){
        let last_inspection = null
        let target = (data.length == 0) ? null : data[0]

        for(let x=0; x<data.length; x++){
            const insp = data[x]
            const date = new Date(insp.deadline)

            if(!last_inspection || date > last_inspection){
                last_inspection = date
                target = insp
            }
        }

        return target
    }

    function renderTable(){
        return (
            <Table>
                <TableRow>
                    <TableCell>Equipamento</TableCell>
                    <TableCell>Processo</TableCell>
                    <TableCell>Nº Inspeções</TableCell>
                    <TableCell>Corrosão Total</TableCell>
                    <TableCell>Degradação</TableCell>
                    <TableCell>Severidade</TableCell>
                    <TableCell>Perda de Material</TableCell>
                    <TableCell>Observações</TableCell>
                </TableRow>

                {equipments.map((item: any) => {
                    const targetInspections = inspections.filter((insp: any) => (item.equipment_id == insp.equipment) && (item.approved == 1))
                    const lastInspection = getLastInspection(targetInspections)
                    const relatory = !lastInspection ? null : JSON.parse(lastInspection.relatory)

                    const sum = targetInspections.length
                    const severity = !relatory ? 'N/A'  : relatory?.SubstrateEvaluation?.corrosionSeverity   
                    const corrosion = !relatory ? 'N/A' : relatory?.SubstrateEvaluation?.corrosion + '%'
                    const materialLoss = !relatory ? 'N/A' : relatory?.CoatingEvaluation?.coatingDamage 
                    const degradation = !relatory ? 'N/A' : relatory?.CoatingEvaluation?.damageClass

                    const obs = !relatory ? 'N/A' : relatory?.CoatingEvaluation?.observations + ', ' + relatory?.SubstrateEvaluation?.observations
                    const targetProcess = processes.filter((proc: any) => proc.process_id == item.process)
                    
                    return (
                        <TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{(targetProcess.length == 0) ? 'N/A' : targetProcess[0].name}</TableCell>
                            <TableCell>{sum}</TableCell>
                            <TableCell>{corrosion}</TableCell>
                            <TableCell>{degradation}</TableCell>
                            <TableCell>{severity}</TableCell>
                            <TableCell>{materialLoss}</TableCell>
                            <TableCell>{obs}</TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        )
    }

    function renderBarData(){
        const targetInspections = inspections.filter((item: any) => item.plant == selectedPlant.plant_id)
        const targetReports = targetInspections.map((item: any) => JSON.parse(item.relatory ? item.relatory : '{}'))
        const recPoints = targetReports.map((item: any) => (item?.Recommendations?.points) ? (item?.Recommendations?.points) : '')

        let defaultData = [
            { 
                key: 'reinspect_after', 
                label: 'RE-INSPECIONAR APÓS', 
                one_year: recPoints.filter((points: string) => points.includes('RE-INSPECIONAR APÓS') && points.includes('1')).length, 
                two_years: recPoints.filter((points: string) => points.includes('RE-INSPECIONAR APÓS') && points.includes('2')).length,
                three_years: recPoints.filter((points: string) => points.includes('RE-INSPECIONAR APÓS') && points.includes('3')).length,
            },
            { 
                key: 'localized_repairs', 
                label: 'REPAROS LOCALIZADOS EM ATÉ', 
                one_year: recPoints.filter((points: string) => points.includes('REPAROS LOCALIZADOS EM ATÉ') && points.includes('1')).length, 
                two_years: recPoints.filter((points: string) => points.includes('REPAROS LOCALIZADOS EM ATÉ') && points.includes('2')).length,
                three_years: recPoints.filter((points: string) => points.includes('REPAROS LOCALIZADOS EM ATÉ') && points.includes('3')).length,
            },
            { 
                key: 'substitution', 
                label: 'SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ', 
                one_year: recPoints.filter((points: string) => points.includes('SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ') && points.includes('1')).length, 
                two_years: recPoints.filter((points: string) => points.includes('SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ') && points.includes('2')).length,
                three_years: recPoints.filter((points: string) => points.includes('SUBSTITUIÇÃO OU REPARAÇÃO EM ATÉ') && points.includes('3')).length,
            },
        ];
        
        let defaultBars = [
            {key: 'one_year',    label: '1 ANO',  color: '#E1A58E'},
            {key: 'two_years',   label: '2 ANOS', color: '#F36934'},
            {key: 'three_years', label: '3 ANOS', color: '#F24000'},
        ]

        return (
            <div style={{display: 'flex', width: '100%', height: '55vh'}}>
                <CustomBarChart data={defaultData} bars={defaultBars}/>
            </div>
        )
    }

    function renderData(){
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{padding: '.7rem'}}/>
                
                <div className='TextStyle' style={{textAlign: 'center'}}>
                    % de Corrosão - {selectedPlant.name}
                </div>
                    
                <div style={{padding: '.7rem'}}/>
                {renderTable()}

                <div style={{padding: '.7rem'}}/>

                <div className='TextStyle' style={{textAlign: 'center'}}>
                    Gráficos - {selectedPlant.name}
                </div>

                <div style={{padding: '.7rem'}}/>
                {renderGraphs()}

                <div style={{padding: '1rem'}}/>
                {renderBarData()}
            </div>
        )
    }

    return (
        <div className='MainContainer'>
            <FlagSelector/>
            <Sidebar/>
            <div className='SideBarCompensation'/>

            <div className='CentralBlock'>
                <ScrollView className='CorrosionContainer'>
                    <div className='HeaderContainer'>
                        <FontAwesomeIcon 
                            icon={faAngleLeft}
                            color='white'
                            fontSize='1.5rem'
                            cursor='pointer'
                            onClick={() => navigate(-1)}
                        />

                        <div style={{width: '2%'}}/>

                        <div className='TextStyle'>Índices de Corrosão</div>
                    </div>

                    <div style={{padding: '1rem'}}/>

                    <div 
                        style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                        }}
                    >
                        <div style={{width: '80%'}}>
                            <CustomDropBox
                                options={companies}
                                value={selectedCompany.name}
                                setValue={setSelectedCompany}
                                placeholder='Selecione a Empresa' 
                                key='label'
                                callbackKey={null}
                            />
                        </div>

                        <div style={{padding: '.7rem'}}/>

                        <div style={{width: '80%'}}>
                            <CustomDropBox
                                options={plants}
                                value={selectedPlant.name}
                                setValue={setSelectedPlant}
                                placeholder='Selecione a planta' 
                                key='label'
                                callbackKey={null}
                            />
                        </div>

                        <div style={{padding: '.7rem'}}/>
                        
                        <div
                            className={'buttonCorrosion'}
                            style={(loading || !active) ? {cursor: 'not-allowed', opacity: .7} : {}}
                            onClick={(loading || !active) ? () => null : async () => {
                                setLoading(true);
                                await handleButtonClick();
                                setLoading(false)
                            }}
                        >
                            {loading ?
                                (<div className='loadingCircle'/> )
                                : (
                                <div className='buttonCorrosion' style={{width: '100%', cursor: active ? 'pointer' : 'not-allowed'}}>
                                    <FontAwesomeIcon icon={faSearch} fontSize={'1.2rem'} color='white'/>
                                    <div style={{width: '9%'}}/>
                                    <div style={{color: 'white'}}>Consultar</div>
                                </div>
                                )
                            }
                        </div>

                        {showData && renderData()}
                    </div>
                </ScrollView>
            </div>
        </div>
    )
}