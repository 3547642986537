import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCamera, faSearch, faMap, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

import Sidebar from '../../../components/Sidebar';
import { useRef, useState } from 'react';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { deleteJsonData, getStoredJson, saveJsonData, sleep, useInitialCheck } from '../../../utils';
import { getDataBaseCondition, getDatabaseRows, getImagePaths } from '../../../utils/database';
import lang from './lang';
import LoadingDiv from '../../../components/LoadingDiv';


export function ListPage() {
    const [searchText, setSearchText] = useState<any>('');
    const [target, setTarget] = useState<string>('company')
    const [allOptions, setAllOptions] = useState<any>([null])
    const [allInspections, setAllInspections] = useState<any>([null])
    const [update, setUpdate] = useState<boolean>(true)

    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const user = getStoredJson('user')
    const currLang = globalOptions.language

    const navigate = useNavigate()
    useInitialCheck(importInfo)

    async function importInfo(){
        await sleep(500)
        const data = getStoredJson('searchItems', {})
        const dbInspections = await getDatabaseRows("inspections")

        let allData = []
        let search  = ''

        if(data.process){
            search = 'equipment'
            allData = await getDataBaseCondition('equipments', 'process', data.process)
        }
        else if(data.plant){
            search = 'process'
            allData = await getDataBaseCondition('processes', 'plant', data.plant)
        }
        else if(data.company){
            search = 'plant'
            allData = await getDataBaseCondition('plants', 'company', data.company)
        }
        else{
            search = 'company'
            
            if(user?.company == '123456789/0001')
                allData = await getDatabaseRows('companies')
            else
                allData = await getDataBaseCondition('companies', 'cnpj', user?.company)
        }

        for(let x=0; x<allData.length; x++){
            const newData = allData[x]
            const images  = await getImagePaths(newData.img_path)
            
            allData[x].img = images.length > 0 ? images[0] : null
        }

        setTarget(search)
        setAllOptions(allData)
        setAllInspections(dbInspections)
        setUpdate(false)
    }

    function renderCameraIcon(img: any, width: string, height: string){
        if(!img || img == '')
            return (
                <div className='cameraContainer' style={{width: width, height: height}}>
                    <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
                </div>
            )
        
        return (<img className='cameraContainer' style={{width: width, height: height}} src={img} alt=''/>)
    }

    function renderProgressBar(item: any){
        let inspections;
        let missingInspections;
        let phPercentage = 1;

        if(target == 'company'){
            inspections = allInspections.filter((insp: any) => insp.company_id == item.id);
            missingInspections = inspections.filter((insp: any) => insp.approved == 1)
        } else if(target == 'plant'){
            inspections = allInspections.filter((insp: any) => insp.plant == item.plant_id);
            missingInspections = inspections.filter((insp: any) => insp.approved == 1)
        } else if(target == 'process'){
            inspections = allInspections.filter((insp: any) => insp.process == item.process_id);
            missingInspections = inspections.filter((insp: any) => insp.approved == 1)
        }
        
        let percentage = (((missingInspections?.length)/inspections?.length)*100)
        console.log(percentage)

        return (
            <div>
                <div style={{display: 'flex', width: '100%'}}>
                    <div className='barContainerFilled' style={percentage ? {width: String(percentage) + '%'} : {width: String(phPercentage) + '%'}}/>
                    <div className='barContainerEmpty'  style={percentage ? {width: String(100-percentage) + '%'} : {width: String(100-phPercentage) + '%'} }/>
                </div>
                <p style={{display: 'flex', color: '#F36934', justifyContent: 'flex-end'}}>
                    {missingInspections?.length} {lang.of[currLang]} {inspections?.length} {lang.inspected[currLang]}
                </p>
            </div>
        )
    }

    function handleArrowClick(item: any) {
        saveJsonData('searchRegion', item)
        return navigate('/Region')
    }

    function compare(original: string, text: string){
        original = original.trim().toLowerCase()
        text     = text.trim().toLowerCase()

        return original.startsWith(text)
    }

    function EquipmentSelection ({item}: any){
        if(!item)
            return <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}/>
        
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', height: '12rem'}}>
                    <div style={{display: 'flex', width: '90%', paddingTop: '10px'}}>
                        {renderCameraIcon(item.img, '13vw', '85%')}

                        <div style={{padding: '.8rem'}}/>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '65%'}}><div/>
                        
                        <div>
                            <p className="individualText">{item.name}</p>
                            <div style={{padding: '.8rem'}}/>
                            {renderProgressBar(item)}
                        </div>

                        <div/>
                        <div/>
                        </div>
                    </div>

                    <div className='arrow_List'
                    onClick={() => handleArrowClick(item)}
                    >
                        <FontAwesomeIcon icon={faAngleRight}
                                         style={{padding: '1rem', fontSize: '2rem'}}
                                         className="iconLeft"/>
                    </div>
                </div>

                <div className="line" style={{opacity: .8, marginTop: '10px', marginBottom: ''}}/>
            </div>
        )
    }

    function renderEquipmentsOptions(){
        let dataEquipments = (searchText.trim()?.length == 0) ? allOptions :
                              allOptions.filter((equipment: any) => compare(equipment.name, searchText))     
        
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {dataEquipments.map((equipment: any) => <EquipmentSelection item={equipment}/>)}
            </div>
        )
    }

    function renderProcessesOptions() {
        let dataProcess = (searchText.trim().length == 0) ? allOptions :
                           allOptions.filter((process: any) => compare(process?.name, searchText))

        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {dataProcess.map((process: any) => <EquipmentSelection item={process}/>)}
            </div>
        )
    }

    function renderPlantsOptions(){
        let dataPlants = (searchText.trim().length == 0) ? allOptions :
                          allOptions.filter((plant: any) => compare(plant.name, searchText))

        if(dataPlants.length == 0 || dataPlants[0] == null)
            return (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '2rem'}}>
                    <div style={{fontSize: '20px', color: 'white'}}>{lang.noFound[currLang]}</div>
                </div>
            )
        
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {dataPlants.map((plant: any) => <EquipmentSelection item={plant}/>)}
            </div>
        )
    }
    
    function renderCompaniesOptions(){
        let dataCompany = (searchText.trim().length == 0) ? allOptions :
                           allOptions.filter((companies: any) => compare(companies.name, searchText))

        if(dataCompany.length == 0)
            return (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '2rem'}}>
                    <div style={{fontSize: '15px', color: 'white'}}>{lang.noFound[currLang]}</div>
                </div>
            )
        
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {dataCompany.map((company: any) => <EquipmentSelection item={company}/>)}
            </div>
        )
    }
    
    function leftListBlock(){
        return (
            <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesomeIcon icon={faAngleLeft} className="iconLeft" style={{fontSize: '2rem', paddingRight: '1.2rem', marginLeft: '-40px'}} onClick={() => navigate('/Map')}/>

                <div className='inputContainer_List'>
                    <FontAwesomeIcon icon={faSearch} className='searchIcon'/>
                    
                    <input
                        type="text"
                        value={searchText}
                        onChange={(event: any) => setSearchText(event.target.value)}
                        placeholder={lang.typeHere[currLang]}
                        className='myinput_list'
                    />
                </div>
            </div>
        )
    }

    async function gotoMap() {
        const data = getStoredJson('searchItems')
        const list = await getDataBaseCondition('plants', 'company', data?.company)
        const generalList = await getDatabaseRows('plants', 100)

        if (target != 'company'){
            saveJsonData('plantList', list)
        } else {
            saveJsonData('plantList', generalList)
        }
        navigate('/Map')
    }

    function renderIconMap(){
        return (
            <div className='map_List' 
                 onClick={async () => await gotoMap()} 
            >
              {lang.map[currLang]}
              <div style={{padding: '.2rem'}}/>
              <FontAwesomeIcon icon={faMap} className="icon_List" />
            </div>
        )
    }

    function renderOptions(){
        if(target == 'company')
            return renderCompaniesOptions()
        if(target == 'plant')
            return renderPlantsOptions()
        if(target == 'process')
            return renderProcessesOptions()
        if(target == 'equipment')
            return renderEquipmentsOptions()

        return (<div/>)
    }

    return (
        <div className="ExploreMainContainer">
            <Sidebar activePage='Search'/>
            <div className='SideBarCompensation'/>

            <div className='CentralBlock'>
                <div className="content_List">
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '80%', alignItems: 'center', justifyContent: 'center'}}>
                        <div className="content-wrapper_List">
                            {leftListBlock()}
                            
                            <div style={{padding: '.5rem'}}/>

                            <LoadingDiv loading={update} className="upperSide_List">
                                {renderOptions()}                 
                            </LoadingDiv>
                        </div>
                    </div>

                    {renderIconMap()}
                </div>
            </div>
    </div>
  );
}