import './styles.css';
import Sidebar from '../../components/Sidebar';
import FlagSelector from '../../components/FlagSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronRight, faUser, faFolderClosed, faFilePen, faInfo, faAngleLeft, faFileImage, faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { getStoredJson, saveJsonData, sleep, useInitialCheck } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../contexts/GlobalContext';

import CustomPieChart from '../../components/Graphs/CustomPieChart';
import CustomLinePlot from '../../components/Graphs/CustomLineChart';
import CustomDonutChart from '../../components/Graphs/CustomDonutChart';
import { editDatabaseByCondition, getDataBaseCondition, getDatabaseRows } from '../../utils/database';
import lang from './lang';
import CustomCalendar from '../../components/CustomCalendar';
import LoadingDiv from '../../components/LoadingDiv';


export function ManagmentPage(){
    const [activeButton, setActiveButton] = useState('Inspector')
    const [searchText, setSearchText] = useState<any>('');

    const [individualInfo, setIndividualInfo] = useState<any>(false)
    const [selectedCompany, setSelectedCompany] = useState<any>(false)
    const [activeUser, setActiveUser] = useState('client')

    const [monthInspections, setMonthInspections] = useState<any>([{x: 0, y: 0}])
    const [semanalInspections, setSemanalInspections] = useState<any>([{x: 0, y: 0}])
    const [otdInspections, setOtdInspections] = useState<any>([{x: 0, y: 0}])
    const [inspectionsStatus, setInspectionsStatus] = useState<any>([{x: 0, y: 0}])
    const [userInspections, setUserInspections] = useState<any>([])

    const [totalInspections, setTotalInspections] = useState(0)
    const [totalRegisters, setTotalRegisters] = useState(0)
    const [pendingInspections, setPendingInspections] = useState(0)
    const [update, setUpdate] = useState<boolean>(true)

    const [desiredData, setDesiredData] = useState<any>([])
    const user = getStoredJson('user')
    const permissions = JSON.parse(user.permission)
  
    const navigate = useNavigate()
    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language

    useInitialCheck(getUserInspections)
    useInitialCheck(updateSelected, [globalOptions])
    useInitialCheck(importPermissions, [individualInfo])
    useInitialCheck(updateOptions, [selectedCompany, individualInfo])
    useInitialCheck(handleChangeText, [searchText, activeButton])
    useInitialCheck(
        async () => {setUpdate(true); setDesiredData([]); await sleep(500); await importInfo(); setUpdate(false)},
        [selectedCompany, activeButton]
    )


    const [checkListOptions, setCheckListOptions] = useState<any>([])
    useInitialCheck(getPermissions, [currLang])

    function getPermissions(){
        setCheckListOptions([
            {id: 'ger_inspectors', title: lang.inspectorManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_clients', title: lang.clientManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_plants', title: lang.plantManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_processes', title: lang.processManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_equipments', title: lang.equipmentManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_companies', title: lang.companyManagement[currLang], read: false, edit: false, register: false},
            {id: 'ger_reports', title: lang.reportManagement[currLang], read: false, edit: false, register: false}
        ])
    }

    function generalEditPermission() {
        if(!individualInfo)
            return false

        if(user.company == '123456789/0001')
            return true

        if(user.category == 'manager')
            return true

        return false
    }

    function getCategory(category: string, reverse: boolean=false){
        const categories= [
          {value: "manager", name: lang.manager[currLang] },
          {value: "auxiliary" , name: 'Auxiliar'},
          {value: "admin", name: lang.admin[currLang] }
        ]
      
        if(!reverse)
          for(let item of categories)
            if(category == item.name)
              return item.value
      
        for(let item of categories)
          if(category == item.value)
            return item.name
      
        return ''
    }    
    
    function handleActiveButton(value: string){
        setIndividualInfo(false)
        setActiveButton(value)
    }
    
    async function updateOptions(){
        let allInspections
        let allRegisters
        let allPendingInspections
        let allEquipment

        if(individualInfo){
            allRegisters = await getDataBaseCondition('registers', 'user_id', individualInfo.id)
            allInspections = await getDataBaseCondition('inspections', 'user_id', individualInfo.id)
            allPendingInspections = (await getDataBaseCondition('inspections', 'is_checked', '0')).filter((item: any) => item.user_id == individualInfo.id)
        }
        else if(selectedCompany){
            allRegisters = await getDataBaseCondition('registers', 'company', selectedCompany.cnpj)
            allInspections = await getDataBaseCondition('inspections', 'company', selectedCompany.cnpj)
            allPendingInspections = (await getDataBaseCondition('inspections', 'is_checked', '0')).filter((item: any) => item.company == selectedCompany.cnpj)
            allEquipment = await getDataBaseCondition('equipments', 'company', selectedCompany.cnpj)
        }
        else{
            allRegisters = await getDatabaseRows('registers')
            allInspections = await getDatabaseRows('inspections')
            allPendingInspections = await getDataBaseCondition('inspections', 'is_checked', '0')
            allEquipment = await getDatabaseRows('equipments')
        }
        
        setTotalRegisters(allRegisters.length)
        setTotalInspections(allInspections.length)
        setPendingInspections(allPendingInspections.length)

        setOtdInspections([
            {name: 'Em dia', value: allInspections.filter((item: any) => item.approved_date <= item.deadline).length, color: '#E1A58E'},
            {name: 'Atrasadas', value: (allInspections.length - allInspections.filter((item: any) => item.approved_date <= item.deadline).length) , color: '#F36934'}
        ])

        setInspectionsStatus([
            {name: 'Não programada', value: (allEquipment?.length - Array.from(new Set(allInspections.map((item: any) => item.equipment))).length), color: '#F24000'},
            {name: 'Programada', value: (Array.from(new Set(allInspections.filter((item: any) => item.approved == 0).map((item: any) => item.equipment)))).length, color: '#F36934'},
            {name: 'Inspecionada', value: (Array.from(new Set(allInspections.filter((item: any) => item.approved == 1).map((item: any) => item.equipment)))).length, color: '#E1A58E'}
        ])

        setMonthInspections([
            {x: 'jan', y: allInspections.filter((item: any)=> getMonthCreated(item, 1)).length},
            {x: 'fev', y: allInspections.filter((item: any)=> getMonthCreated(item, 2)).length},
            {x: 'mar', y: allInspections.filter((item: any)=> getMonthCreated(item, 3)).length},
            {x: 'abr', y: allInspections.filter((item: any)=> getMonthCreated(item, 4)).length},
            {x: 'mai', y: allInspections.filter((item: any)=> getMonthCreated(item, 5)).length},
            {x: 'jun', y: allInspections.filter((item: any)=> getMonthCreated(item, 6)).length},
            {x: 'jul', y: allInspections.filter((item: any)=> getMonthCreated(item, 7)).length},
            {x: 'ago', y: allInspections.filter((item: any)=> getMonthCreated(item, 8)).length},
            {x: 'set', y: allInspections.filter((item: any)=> getMonthCreated(item, 9)).length},
            {x: 'out', y: allInspections.filter((item: any)=> getMonthCreated(item, 10)).length},
            {x: 'nov', y: allInspections.filter((item: any)=> getMonthCreated(item, 11)).length},
            {x: 'dez', y: allInspections.filter((item: any)=> getMonthCreated(item, 12)).length},
        ])
        console.log("mensais: " + JSON.stringify(monthInspections.map((item: any) => item)))

        setSemanalInspections([
            {x: 'seg', y: allInspections.filter((item: any)=> (getDayOfWeek(item) == 'seg')).length},
            {x: 'ter', y: allInspections.filter((item: any)=> (getDayOfWeek(item) == 'ter')).length},
            {x: 'qua', y: allInspections.filter((item: any)=> (getDayOfWeek(item) == 'qua')).length},
            {x: 'qui', y: allInspections.filter((item: any)=> (getDayOfWeek(item) == 'qui')).length},
            {x: 'sex', y: allInspections.filter((item: any)=> (getDayOfWeek(item) == 'sex')).length},
        ])
    }

    async function getUserInspections(){
        const response = await getDataBaseCondition('inspections', 'requested_by', user.id)
        setUserInspections(response.filter((item: any) => item.approved == 0))
    }

    async function updateSelected(){
        const selectedWindow = globalOptions.activeButton
        
        setActiveButton(selectedWindow)
        setIndividualInfo(false)

        if(selectedWindow == 'Company' || selectedWindow == 'Client' || selectedWindow == 'Inspector')
            setSelectedCompany(false)
    }

    async function importInfo(){
        if(activeButton == 'Company'){
            let data = []

            if(user.company == '123456789/0001')
                data = await getDatabaseRows('companies')
            else
                data = await getDataBaseCondition('companies', 'cnpj', user.company)
            
            return setDesiredData(data)
        }

        if(activeButton == 'Plant')
            return setDesiredData(await getDataBaseCondition('plants', 'company', selectedCompany.cnpj))
        
        if(activeButton == 'Process')
            return setDesiredData(await getDataBaseCondition('processes', 'company', selectedCompany.cnpj))
        
        if(activeButton == 'Equipment')
            return setDesiredData(await getDataBaseCondition('equipments', 'company', selectedCompany.cnpj))

        if(activeButton == 'Inspector'){
            const response = await getDataBaseCondition('users', 'category', 'inspector')

            if(user.company == '123456789/0001')
                return setDesiredData(response)

            return setDesiredData(response.filter((item: any) => item.company == user.company))
        }
        
        if(activeButton == 'Client'){
            const response = [
                ...await getDataBaseCondition('users', 'category', 'manager'),
                ...await getDataBaseCondition('users', 'category', 'auxiliary'),
                ...await getDataBaseCondition('users', 'category', 'admin'),
            ]

            if(user.company == '123456789/0001')
                return setDesiredData(response)

            return setDesiredData(response.filter((item: any) => item.company == user.company))  
        }

        setDesiredData([])
    }
    
    async function importPermissions() {
        if(!individualInfo)
            return 
        let permissions = await getDataBaseCondition('users', 'id', individualInfo.id)
        permissions = permissions.map((item: any) => item.permission)
        if(!JSON.stringify(permissions).includes('r'))
            return
        permissions = JSON.parse(permissions);
        
        if(permissions.length == 0)
            return

        setCheckListOptions([
            createOption(lang.inspectorManagement[currLang], permissions.ger_inspectors, 'ger_inspectors'),
            createOption(lang.clientManagement[currLang], permissions.ger_clients, 'ger_clients'),
            createOption(lang.plantManagement[currLang], permissions.ger_plants, 'ger_plants'),
            createOption(lang.processManagement[currLang], permissions.ger_processes, 'ger_processes'),
            createOption(lang.equipmentManagement[currLang], permissions.ger_equipments, 'ger_equipments'),
            createOption(lang.companyManagement[currLang], permissions.ger_companies, 'ger_companies'),
            createOption(lang.reportManagement[currLang], permissions.ger_reports, 'ger_reports'),
        ])
    }

    function createOption(title: string, data: any, key: string){
        const read = data.includes('r');
        const edit = data.includes('a');
        const register = data.includes('c');

        return {id: key, title: title, read: read, edit: edit, register: register}
    }

    function getDayOfWeek(item: any){
        let dateString = (item.last_created_at) ? item.last_created_at :
                         (item.deadline) ? item.deadline : null
        
        if(!dateString)
            return 'dom'

        dateString = dateString.substring(0, dateString.indexOf(' '))
        const daysOfWeek = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
        const date = new Date(dateString);
        const dayIndex = date.getDay();
        return daysOfWeek[dayIndex];
    }

    function getMonthCreated(item: any, month: number){
        if(item.server_created_at)
            return item.server_created_at.split('-')[1] == month

        if(item.deadline)
            return item.deadline.split('-')[1] == month

        return []
    }

    function compare(original: string, text: string){
        original = original.trim().toLowerCase()
        text     = text.trim().toLowerCase()

        return original.startsWith(text)
    }
    
    function handleChangeText(){
        if(searchText.trim().length == 0)
            return importInfo()
        
        setDesiredData(desiredData.filter((item: any) => compare(item.name, searchText)))
    }

    function handleItemClick(item: any){
        if(activeButton == 'Company'){
            setActiveButton('Plant')
            setSelectedCompany(item)
            return setIndividualInfo(false)
        }
        
        setIndividualInfo(item)
    }

    async function sendPermissions(){
        function getPermission(read: boolean, edit: boolean, register: boolean){
            if(read && edit && !register)
                return 'ra'

            if(read && !edit && !register)
                return 'r'

            if(edit && !read && !register)
                return 'a'

            if(register && !read && !edit)
                return 'c'

            if(read && register && !edit)
                return 'rc'

            if(edit && register && !read)
                return 'ac'

            if(read && edit && register)
                return 'rac'

            return 'none'
        }

        let preNewData: any = {}

        for(let permission of checkListOptions){
            let key = permission.id
            preNewData[key] = getPermission(permission.read, permission.edit, permission.register)
        }

        let newData: any = {
            permission: JSON.stringify(preNewData)
        }
        
        const response = await editDatabaseByCondition('users', 'id', individualInfo.id, newData)

        if(!response || response.status != 'success')
            return alert('erro ao se comunicar com banco de dados ' + JSON.stringify(response))

        setActiveButton('Company')
    }

    function renderList(){
        return desiredData.map((item: any) => (
            <div>
                <div className='itemKey'>
                    <div style={{display: 'flex', width: '80%'}}>
                        <p className='whiteText' style={{width: '55%'}}>{item.name}</p>
                        {activeButton == 'Client' && <p className='whiteText'>{getCategory(item.category, true)}</p>}
                    </div>
                    
                    <div style={{display: 'flex'}}>
                        {activeButton == 'Company' && (
                            <div className='infoButtonRight' style={{justifySelf: 'flex-end'}} onClick={() => setIndividualInfo(item)}>
                                <FontAwesomeIcon style={{alignSelf: 'center', color: 'white'}} icon={faInfo}/>
                            </div>
                            )
                        }

                        <div style={{padding: '.2rem'}}/>

                        <div className='infoButtonRight' style={{justifySelf: 'flex-start'}} onClick={() => handleItemClick(item)}>
                            <FontAwesomeIcon style={{alignSelf: 'center', color: 'white'}} icon={faChevronRight}/>
                        </div>
                    </div>
                </div>
                
                <div className='lineList'/>
            </div>
        ))
    }
    
    function onEditRequest(){
        saveJsonData('edit', individualInfo)
        navigate(`/${activeButton}?edit`)
    }

    function handleInspectionRequest(){
        if(activeButton == 'Company')
            saveJsonData('insert', {
                inspector: getStoredJson('user'),
                company: individualInfo.cnpj,
            })

        else if(activeButton == 'Plant')
            saveJsonData('insert', {
                inspector: getStoredJson('user'),
                company: selectedCompany.cnpj,
                plant: individualInfo.plant_id
            })

        else if(activeButton == 'Process')
            saveJsonData('insert', {
                inspector: getStoredJson('user'),
                company: selectedCompany.cnpj,
                plant: individualInfo.plant,
                process: individualInfo.process_id,
            })

        else if(activeButton == 'Equipment')
            saveJsonData('insert', {
                inspector: getStoredJson('user'),
                company: selectedCompany.cnpj,
                plant: individualInfo.plant,
                process: individualInfo.process,
                equipment: individualInfo.equipment_id  
            })

        else if(activeButton == 'Inspector')
            saveJsonData('insert', {
                inspector: individualInfo
            })

        else if(activeButton == 'Client')
            saveJsonData('insert', {
                inspector: individualInfo
            })


        navigate('/Request?insert')
    }

    function handleReportsRequest() {
        if(activeButton == 'Company')
            saveJsonData('filter', {
                company: individualInfo.id,
            })

        else if(activeButton == 'Plant')
            saveJsonData('filter', {
                company: selectedCompany.cnpj,
                plant: individualInfo.plant_id
            })

        else if(activeButton == 'Process')
            saveJsonData('filter', {
                company: selectedCompany.cnpj,
                process: individualInfo.process_id,
            })
        else if(activeButton == 'Equipment')
            saveJsonData('filter', {
                company: selectedCompany.cnpj,
                equipment: individualInfo.equipment_id
            })
        else if(activeButton == 'Inspector')
            saveJsonData('filter', {
                inspector: individualInfo,
            })

        navigate('/Reports?filter')
    }

    function handleRegisterRequest() {
        if(activeButton == 'Company')
            saveJsonData('register', {
                company: individualInfo.cnpj,
            })
        else if(activeButton == 'Plant')
            saveJsonData('register', {
                company: selectedCompany.cnpj,
                plant: individualInfo.plant_id
            })
        else if(activeButton == 'Process')
            saveJsonData('register', {
                company: selectedCompany.cnpj,
                process: individualInfo.process_id,
            })
        else if(activeButton == 'Equipment')
            saveJsonData('register', {
                company: selectedCompany.cnpj,
                equipment: individualInfo.equipment_id
            })
        else if(activeButton == 'Inspector')
            saveJsonData('register', {
                inspector: individualInfo,
            })
        navigate('/Reports?register')
    }

    function editPermission(active: any, info: any) {

        if(active == 'Company'){
            if(user.company == '123456789/0001')
              return true
            
            if(user.company == info.cnpj)
                if(permissions.ger_companies.includes('a'))
                    return true

            return false
        }
        else if(active == 'Inspector' || active == 'Client'){
            if(user.company == '123456789/0001')
              return true

            if(user.category == 'manager' && user.company == info.company)
                return true

            if(user.category != 'manager' && user.name == info.name)
                return true

            if(user.category != 'manager' && user.company == info.company)
                if(permissions.ger_companies.includes('a'))
                    return true

            return false
        }
        else if(active == 'Plant')
            if(!permissions.ger_plants.includes('a'))
                return false 
        else if(active == 'Process')
            if(!permissions.ger_processes.includes('a'))
                return false 
        else if(active == 'Equipment')
            if(!permissions.ger_equipments.includes('a'))
                return false 
            
        return true    
    }
    
    function individualInfoBlock(){
        if(!individualInfo)
            return null

        saveJsonData('edit', individualInfo)
        let editPerm = editPermission(activeButton, individualInfo)

        return (
            <div className='userBlockContainer'>
                <div className='userContainer'  style={{cursor: 'not-allowed'}}>
                    <FontAwesomeIcon className='userIcon' icon={faUser}/>
                    <p className='userText'>{individualInfo?.name}</p>
                </div>
                
                <div style={{padding: '.5rem'}}/>

                <div className='reportContainer' onClick={handleReportsRequest}>
                    <FontAwesomeIcon className='infoIcon' icon={faFolderClosed}/>
                    <p className='userText'>{lang.inspectionReports[currLang]}</p>
                </div>

                <div style={{padding: '.5rem'}}/>

                <div className={`${editPerm ? 'reportContainer' : 'reportContainer_disabled'}`} 
                    onClick={editPerm ? onEditRequest : undefined}>
                    <FontAwesomeIcon className='infoIcon' icon={faFilePen}/>
                    <p className='userText'>{lang.toEdit[currLang]}</p>
                </div>

                <div style={{padding: '.5rem'}}/>

                <div className='reportContainer' onClick={handleInspectionRequest}>
                    <FontAwesomeIcon className='infoIcon' icon={faFilePen}/>
                    <p className='userText'>{lang.newInspection[currLang]}</p>
                </div>

                <div style={{padding: '.5rem'}}/>

                <div className='reportContainer' onClick={handleRegisterRequest}>
                    <FontAwesomeIcon className='infoIcon' icon={faFileImage}/>
                    <p className='userText'>{lang.registers[currLang]}</p>
                </div>
            </div>
        )
    }

    function createLabelOption(text: string, color: string, inspection?: any){
        return (
            <div style={{display: 'flex'}}>
                {inspection == false ? (
                    <>
                        <div className="coloredBox" style={{backgroundColor: color, borderRadius: '1rem' }}/>
                    </>
                    ) : (
                        <FontAwesomeIcon icon={faExclamationCircle} className="coloredIcon" style={{color: color, borderRadius: '1rem'}}/>
                    )
                }
                <div style={{padding: '.3rem'}}/>
                <p className="labelText_Management">{text}</p>
            </div>
        )
    }

    function handleInspectionsClick(selectedEvent: any){
        if(!selectedEvent)
            return

        const data = userInspections[selectedEvent.id]
        saveJsonData('pendingInspection', data)
        navigate('/Explore')
    }

    function infoBlock(){
        return (
        <div style={{width: '100%'}}>
            <div className='buttonColumn'>
                <div className='column1Button'>
                    <div className='buttonText'>{lang.totalInspections[currLang]}</div>
                    <div style={{padding: '5px'}}/>
                    <div className='buttonText'>{totalInspections}</div>
                </div>

                <div style={{padding: 10}}/>

                <div className='column1Button'>
                    <div className='buttonText'>{lang.totalRegisters[currLang]}</div>
                    <div style={{padding: '5px'}}/>
                    <div className='buttonText'>{totalRegisters}</div>
                </div>
                
                <div style={{padding: 10}}/>
                
                <div className='column1Button'>
                    <div className='buttonText'>{lang.pendingInspections[currLang]}</div>
                    <div style={{padding: '5px'}}/>
                    <div className='buttonText'>{pendingInspections}</div>
                </div>
            </div>

            <div style={{padding: 10}}/>
            
            <div className='buttonColumn' >
                <div className='pizzaButton' >
                    <div className='labelCharts2'>
                        {createLabelOption(lang.lateInspection[currLang], '#F36934', false)}
                        {createLabelOption(lang.onTimeInspection[currLang], '#E1A58E', false)}
                    </div>

                    <div className='pizzaContent'>
                        <p className='buttonText' style={{marginBottom: '-5px'}}>{lang.underInspections[currLang]}</p>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                            {
                            totalInspections > 0 ?(
                                <CustomPieChart data={otdInspections}/>
                            ) : (
                                <CustomPieChart hasData={false}/>
                            )}
                        </div>
                    </div>
                </div>

                <div className='pizzaButton'>
                    <div className='labelCharts'>
                        {createLabelOption(lang.notScheduled[currLang], '#F24000', false)}
                        {createLabelOption(lang.scheduled[currLang], '#F36934', false)}
                        {createLabelOption(lang.inspected[currLang], '#E1A58E', false)}
                    </div>

                    <div className='pizzaContent'>
                        <p className='buttonText' style={{marginBottom: '-5px'}}>{lang.statusInspections[currLang]}</p>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                            {
                            totalInspections > 0 ?(
                                <CustomDonutChart data={inspectionsStatus}/>
                            ) : (
                                <CustomDonutChart hasData={false}/>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>

            <div style={{padding: 10}}/>

            <div className='buttonColumn'>
                <div className='lineGraphButton'>
                    <p className='buttonText'>{lang.totalInspections[currLang]}</p>
                    
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                        <CustomLinePlot data={monthInspections}/>
                    </div>
                </div>
            </div>
            
            <div style={{padding: 10}}/>

            <div className='agendaColumn'>
                <div className='registrosButton'>
                    <p className='buttonText'>{lang.semanalRegisters[currLang]}</p>

                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                        <CustomLinePlot data={semanalInspections}/>
                    </div>
                </div>

                <div className='agendaButton'>
                    <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center'}}>
                        <div/>

                        <p className='buttonText'>{lang.calendar[currLang]}</p>

                        <FontAwesomeIcon icon={faPlusCircle} style={{color: 'white', fontSize: '1rem', cursor: 'pointer'}} onClick={() => navigate('/Calendar')}/>
                    </div>
                    
                    <div className="management-calendar-container">
                        <CustomCalendar
                            inspections={userInspections}
                            onClick={handleInspectionsClick}
                            week={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        )
    }

    function leftListBlock(){
        return (
            <div className='listContainer'>
                <div style={{padding: '1rem'}}/>

                <div style={{display: 'flex', width: '100%'}}>
                    {(activeButton == 'Plant' || activeButton == 'Process' || activeButton == 'Equipment')
                        && <FontAwesomeIcon icon={faAngleLeft} className="iconLeft" style={{fontSize: '2rem', paddingRight: '1.2rem',}} onClick={() => {setActiveButton('Company'); setSelectedCompany(false)}}/>
                    }

                    <div className='managementInputContainer'>
                        <FontAwesomeIcon icon={faSearch} className='searchIcon'/>

                        <input
                            type="text"
                            value={searchText}
                            onChange={(event: any) => setSearchText(event.target.value)}
                            placeholder={lang.typeHere[currLang]}
                            className='managementInput'
                        />
                    </div>
                </div>

                <div style={{padding: '.6rem'}}/>
                
                <LoadingDiv loading={update} className='managementListContainer'>
                    {renderList()}
                </LoadingDiv>

                <div style={{padding: '.6rem'}}/>
            </div>
        )
    }
    
    function renderCheckList(){
        function handleCheck(id: string, key: string){
            let copy: any = checkListOptions

            for(let x=0; x<copy.length; x++)
                if(copy[x].id == id)
                    copy[x][key] = !copy[x][key]
            
            setCheckListOptions([...copy])
        }

        return checkListOptions.map((item: any) => (
            <div key={item.id} className='checkListContainer2'>
                <p className='checkListText' style={{justifyContent: 'flex-start'}}>{item.title}</p>

                <div className='checkListText' style={{width: '6rem'}}>
                    <input
                        type="checkbox"
                        checked={item.read}
                        onChange={() => handleCheck(item.id, 'read')}
                    />
                </div>

                <div className='checkListText' style={{width: '6rem'}}>
                    <input
                        type="checkbox"
                        checked={item.edit}
                        onChange={() => handleCheck(item.id, 'edit')}
                    />
                </div>

                <div className='checkListText' style={{width: '6rem'}}>
                    <input
                        type="checkbox"
                        checked={item.register}
                        onChange={() => handleCheck(item.id, 'register')}
                    />
                </div>
            </div>
        ))
    }

    function renderAcessOptionsBlock(){
        return (
            <div style={{width: '100%', height: '60rem'}}>
                <div className='topButtonsContainer'>
                    <div className={activeUser == 'client' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => setActiveUser('client')}>
                        <p className='buttonText'>{lang.clients[currLang]}</p>
                    </div>
                    
                    <div className={activeUser == 'admin' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => setActiveUser('admin')}>
                        <p className='buttonText'>{lang.admin[currLang]}</p>
                    </div>

                    <div className={activeUser == 'inspector' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => setActiveUser('inspector')}>
                        <p className='buttonText'>{lang.inspector[currLang]}</p>
                    </div>
                </div>

                <div style={{padding: '.5rem'}}/>
                
                <div className='accessOptionsRight'>
                    <p className='checkListText' style={{fontSize: '1.5rem', justifyContent: 'flex-start'}}>{lang.modifyAccess[currLang]}</p>

                    <div className='checkListContainer'>
                        <p className='checkListText' style={{justifyContent: 'flex-start'}}>{lang.accessControl[currLang]}</p>

                        <p className='checkListText' style={{width: '6rem'}}>{lang.read[currLang]}</p>

                        <p className='checkListText' style={{width: '6rem'}}>{lang.edit[currLang]}</p>

                        <p className='checkListText' style={{width: '6rem'}}>{lang.register[currLang]}</p>
                    </div>

                    {renderCheckList()}

                    <div style={{padding: '1rem'}}/>

                    <div className={generalEditPermission() ? 'sendButton' : 'sendButton_disabled'}
                         onClick={generalEditPermission() ? async () => await sendPermissions() : undefined}>
                        <p className='checkListText'>{lang.update[currLang]}</p>
                    </div>
                </div>
            </div>
        )
    }

    function renderActiveLeft(){
        if(individualInfo)
            return individualInfoBlock()

        if(activeButton == 'Inspector')
            return leftListBlock()

        if(activeButton == 'Company')
            return leftListBlock()

        if(activeButton == 'Client')
            return leftListBlock()
        
        if(activeButton == 'Plant')
            return leftListBlock()

        if(activeButton == 'Equipment')
            return leftListBlock()
        
        if(activeButton == 'Process')
            return leftListBlock()

        return (<div></div>)
    }

    function renderActiveRight(){
        if(activeButton == 'Inspector')
            return infoBlock()

        if(activeButton == 'Company')
            return infoBlock()

        if(activeButton == 'Client')
            return renderAcessOptionsBlock()

        if(activeButton == 'Plant')
            return infoBlock()

        if(activeButton == 'Equipment')
            return infoBlock()

        if(activeButton == 'Process')
            return infoBlock()

        return (<div></div>)
    }

    function renderButtons(){
        if(!selectedCompany)
            return (
            <div className='topButtonsContainer'>
                <div className={activeButton == 'Company' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Company')}>
                    <p className='buttonText'>{lang.companies[currLang]}</p>
                </div>

                <div style={{padding: '.8rem'}}/>
                
                <div className={activeButton == 'Inspector' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Inspector')}>
                    <p className='buttonText'>{lang.inspectors[currLang]}</p>
                </div>

                <div style={{padding: '.8rem'}}/>

                <div className={activeButton == 'Client' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Client')}>
                    <p className='buttonText'>{lang.accesses[currLang]}</p>
                </div>
            </div>
            )

        return (
            <div className='topButtonsContainer'>
                <div className={activeButton == 'Plant' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Plant')}>
                    <p className='buttonText'>Planta</p>
                </div>
                
                <div className={activeButton == 'Process' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Process')}>
                    <p className='buttonText'>Processos</p>
                </div>

                <div className={activeButton == 'Equipment' ? 'topButtonEnabled' : 'topButtonDisabled'} onClick={() => handleActiveButton('Equipment')}>
                    <p className='buttonText'>Equipamentos</p>
                </div>
            </div>
        )
    }

    return (
        <div className='MainContainer'>
            
            <div className='SideBarCompensation'/>

            <div className='CentralBlock' style={{alignSelf: 'flex-start'}}>
                <FlagSelector/>
                <Sidebar activePage='Management'/>
                
                <div style={{padding: '2.5%'}}/>

                <div className='mainManagmentContainer'>
                    <div className='leftContainer'>
                        {renderButtons()}
                        
                        <div style={{padding: '.5rem'}}/>
                        
                        <div className='companyContainer'>
                            {renderActiveLeft()}
                        </div>
                    </div>
                
                    <div style={{padding: '1rem'}}/>
                    
                    <div className='rightContainer'>
                        {renderActiveRight()}
                    </div>
                </div>

                <div style={{padding: '.7rem'}}/>
            </div>
        </div>
    )
}