import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCamera, faFileExcel, faFileExport } from "@fortawesome/free-solid-svg-icons";
import 'jspdf-autotable';
import ExcelJS, { BorderStyle } from 'exceljs';
import axios from 'axios'

import './styles.css'
import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import { getStoredJson, saveJsonData, useInitialCheck } from "../../../utils";
import { editDatabaseByCondition, getDataBaseCondition, getImagePaths } from "../../../utils/database";
import { formatTimestamp } from "../../../components/DatePicker";
import logoImage from '../../../assets/img/logo.png';
import { useGlobalContext } from "../../../contexts/GlobalContext";
import lang from "./lang";

export function FormViewPage() {
    const [selectedOption, setSelectedOption] = useState('visualInspection')
    const [isSmallSizeWindow, setIsSmallSizeWindow] = useState(window.innerWidth < 760)

    const storedForm: any = getStoredJson('formVisualized')
    const storedAsset: any = getStoredJson('searchRegion')
    const formData: any = storedForm.form_data ? JSON.parse(storedForm.form_data) : {}    

    const [isLoading, setIsLoading] = useState(true)
    const [equipment, setEquipment] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [companyImg, setCompanyImg] = useState<string>('')
    const [plant, setPlant] = useState<string>('')
    const [process, setProcess] = useState<string>('')
    const [images, setImages] = useState<any>([])
    const [form, setForm] = useState<any>([])
    const [fullEquipment, setFullEquipment] = useState<any>([])
    const [showPDFPopup, setShowPDFPopup] = useState(false);

    const { globalOptions, setGlobalOptions } = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()
    
    function updateScreenSize() {
        setIsSmallSizeWindow(window.innerWidth < 760)
    }

    useEffect(() => {
        setIsLoading(false)
    }, [])


    useEffect(() => {
        window.addEventListener('resize', updateScreenSize)
        return () => window.removeEventListener('resize', updateScreenSize)
    })


    function renderOption(key: string, value: any, line: boolean = true, opacity: number = 0.8) {
        const typeValue = typeof value === 'object' ? value.value : value;
        const displayJustify = typeof value === 'object' ? value.justify : '';
        let displayValue, age

        if (key == lang.plantAge[currLang] || key == lang.processAge[currLang] || key == lang.equipmentAge[currLang])
            age = true

        if (key != lang.installationConstruction[currLang]){
            if (typeValue === 1)
                displayValue = lang.yes[currLang]
            else if (typeValue === 2)
                displayValue = lang.no[currLang]
            else 
                displayValue = typeValue
        } else {
            if (typeValue === 1)
                displayValue = lang.otherMaterial[currLang]
            else if (typeValue === 2)
                displayValue = lang.galvanizedSteel[currLang]
            else if (typeValue === 3)
                displayValue = lang.stainlessSteel[currLang]
            else if (typeValue === 4)
                displayValue = lang.carbonSteel[currLang]
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={key}>
                {!line && (<div style={{ padding: '.2rem' }} />)}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.1rem', opacity: '0.8', color: 'white', textAlign: 'justify', width: '85%'  }}>{key}</p>
                    <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white'}}>{age ? `${displayValue} ${lang.years[currLang]}` : displayValue}</p>
                </div>
                {typeValue === 1 && (
                    <>
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', alignItems: 'center', marginTop: '-1.5rem' }}>
                            <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', textAlign: 'justify' }}>{displayJustify}</p>
                        </div>
                    </>
                )}
                
                <div style={{ padding: '.2rem' }} />

                {line && (<div className="line" style={{ opacity: opacity }} />)}
                {line && (<div style={{ padding: '.2rem' }} />)}
            </div>
        )
    }

    function renderActive() {
        if (storedAsset.target === 'plant' || storedAsset.target === 'process')
            return (
                <div>
                    {renderOption(storedAsset.target === 'process' ? lang.processAge[currLang] : lang.plantAge[currLang], formData.plantAge.value)}
                    {renderOption(lang.eqpAdd[currLang], formData.eqpAdd)}
                    {renderOption(lang.acc[currLang], formData.acc)}
                    {renderOption(lang.coatingType[currLang], formData.coatingType)}
                    {renderOption(lang.lastPainting[currLang], formData.lastPainting)}
                    {renderOption(lang.tfm[currLang], formData.tfm)}
                    {renderOption(lang.historicalData[currLang], formData.historicalData)}
                    {renderOption(lang.safetyProblems[currLang], formData.safetyProblems)}
                    {renderOption(lang.repairFactors[currLang], formData.repairFactors)}
                    {renderOption(lang.thermalInsulation[currLang], formData.thermalInsulation)}
                    {renderOption(lang.chemicalsAffectingCoating[currLang], formData.chemicalsAffectingCoating)}
                    {renderOption(lang.installationConstruction[currLang], formData.specific)}
                </div>
            )
        else if (storedAsset.target === 'equipment')
            return (
                <div>
                    {renderOption(lang.equipmentAge[currLang], formData.plantAge.value)}
                    {renderOption(lang.newEquipmentAdded[currLang], formData.newEquipmentAdded)}
                    {renderOption(lang.ACCCondition[currLang], formData.ACCCondition)}
                    {renderOption(lang.coatingSystem[currLang], formData.coatingSystem)}
                    {renderOption(lang.lastMaintenance[currLang], formData.lastMaintenance)}
                    {renderOption(lang.TFMBeforeFailure[currLang], formData.TFMBeforeFailure)}
                    {renderOption(lang.reasonForFailure[currLang], formData.reasonForFailure)}
                    {renderOption(lang.historicalCoatingData[currLang], formData.historicalCoatingData)}
                    {renderOption(lang.equipmentFailureDueToCorrosion[currLang], formData.equipmentFailureDueToCorrosion)}
                    {renderOption(lang.safetyIncidents[currLang], formData.safetyIncidents)}
                    {renderOption(lang.timeSinceFailure[currLang], formData.timeSinceFailure)}
                    {renderOption(lang.repairMaintenanceFactors[currLang], formData.repairMaintenanceFactors)}
                    {renderOption(lang.thermalInsulationOnEquipment[currLang], formData.thermalInsulationOnEquipment)}
                    {renderOption(lang.insulationSurfaceInspection[currLang], formData.insulationSurfaceInspection)}
                    {renderOption(lang.lastInspectionDate[currLang], formData.lastInspectionDate)}
                    {renderOption(lang.identifyThermalSystems[currLang], formData.identifyThermalSystems)}
                    {renderOption(lang.damagedInsulationMaterial[currLang], formData.damagedInsulationMaterial)}
                    {renderOption(lang.equipmentInUse[currLang], formData.equipmentInUse)}
                    {renderOption(lang.thermalTracing[currLang], formData.thermalTracing)}
                    {renderOption(lang.mixedMaterials[currLang], formData.mixedMaterials)}
                    {renderOption(lang.chemicalsAffectingEquipmentCoating[currLang], formData.chemicalsAffectingEquipmentCoating)}
                    {renderOption(lang.acidFumes[currLang], formData.acidFumes)}
                    {renderOption(lang.installationConstruction[currLang], formData.specific)}
                </div>
            )

        return (<div />)
    }

    return (
        <div className="MainContainer">
            <FlagSelector />
            <Sidebar activePage='Explore' />

            <div className='SideBarCompensation' />

            <div className="CentralBlock">
                <div className="ExploreInfoContainer">
                    <div className="ExploreRow">
                        <div id="exploreTitleContainer">
                            <button onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={faAngleLeft} className="backIcon" />
                            </button>

                            <p className="dateText">{storedAsset.name}</p>
                        </div>
                    </div>

                    <div className="line" />
                    <div style={{ padding: '.5rem' }} />

                    <div className="scrollViewForm">
                        {renderActive()}
                    </div>
                </div>
            </div>
        </div>
    )
}